<template>
  <b-card>
    <b-card-text>
      <b-container>
        <b-row>
          <b-col md="6">
            <h4>Precios de lista</h4>
          </b-col>

          <!-- #region::Price to be decided checkbox -->
          <b-col
            md="6"
            class="d-flex"
          >
            <b-form-checkbox
              v-model="priceToBeDecided"
              class="ml-auto"
            >
              <strong>Precio por definir</strong>
            </b-form-checkbox>
          </b-col>
          <!-- #endregion::Price to be decided checkbox -->

          <!-- #region::Normal min and max prices inputs -->
          <b-col md="12">
            <b-row align-h="start">

              <!-- #region::Normal min price -->
              <b-col md="4">
                <TextInputWithValidation
                  v-model="minNormalPrice"
                  vid="minNormalPrice"
                  rules="required|decimal"
                  type="text"
                  label="Precio mínimo"
                  name="precio mínimo"
                  maxlength="12"
                  placeholder="100.00"
                  prepend="$"
                  :disabled="priceToBeDecided"
                />
              </b-col>
              <!-- #endregion::Normal min price -->

              <!-- #region::Normal max price -->
              <b-col md="4">
                <TextInputWithValidation
                  v-model="maxNormalPrice"
                  vid="maxNormalPrice"
                  rules="required|decimal|min_value:@minNormalPrice"
                  type="text"
                  label="Precio máximo"
                  name="precio máximo"
                  maxlength="12"
                  placeholder="100.00"
                  prepend="$"
                  :disabled="priceToBeDecided"
                />
              </b-col>
              <!-- #endregion::Normal max price -->

              <!-- #region::Offer price -->
              <b-col md="4">
                <TextInputWithValidation
                  v-model="offerPrice"
                  vid="offerPrice"
                  rules="decimal|max_value:@maxNormalPrice"
                  type="text"
                  label="Precio oferta"
                  name="precio oferta"
                  maxlength="12"
                  placeholder="100.00"
                  prepend="$"
                  :disabled="priceToBeDecided"
                />
              </b-col>
              <!-- #endregion::Offer price -->

            </b-row>
          </b-col>
        <!-- #endregion::Normal min and max price inputs -->

        </b-row>
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BFormCheckbox, BCard, BCardText, BContainer, BRow, BCol,
} from 'bootstrap-vue'

import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BContainer,
    BFormCheckbox,
    TextInputWithValidation,
  },
  computed: {
    ...mapGetters({
      getSelectedType: 'products/getSelectedType',
      getMinNormalPrice: 'products/getMinNormalPrice',
      getMaxNormalPrice: 'products/getMaxNormalPrice',
      // Respaldo para los precios normales de lista
      getBackMinNormalPrice: 'products/getBackMinNormalPrice',
      getBackMaxNormalPrice: 'products/getBackMaxNormalPrice',
      // Banderas para el setting de precios o si habrá precios de mayorista
      getPriceToBeDecided: 'products/getPriceToBeDecided',
      // Precio de oferta
      getOfferPrice: 'products/getOfferPrice',
      // Respaldo del precio de oferta
      getBackOfferPrice: 'products/getBackOfferPrice',
    }),
    selectedType: {
      get() { return this.getSelectedType },
    },
    /**
     * Precio de lista normal mínimo
     */
    minNormalPrice: {
      get() { return this.getMinNormalPrice },
      set(value) { this.setMinNormalPrice(value) },
    },
    /**
     * Precio de lista normal máximo
     */
    maxNormalPrice: {
      get() { return this.getMaxNormalPrice },
      set(value) { this.setMaxNormalPrice(value) },
    },
    /**
     * Respaldo para los precios normales de lista
     */
    backMinNormalPrice: {
      get() { return this.getBackMinNormalPrice },
      set(value) { this.setBackMinNormalPrice(value) },
    },
    /**
     * Respaldo para los precios máximos de lista
     */
    backMaxNormalPrice: {
      get() { return this.getBackMaxNormalPrice },
      set(value) { this.setBackMaxNormalPrice(value) },
    },
    /**
     * Indica si se establecen los precios de lista
     */
    priceToBeDecided: {
      get() { return this.getPriceToBeDecided },
      set(value) { this.setPriceToBeDecided(value) },
    },
    /**
     * Precio de oferta
     */
    offerPrice: {
      get() { return this.getOfferPrice },
      set(value) { this.setOfferPrice(value) },
    },
    /**
     * Respaldo del precio de oferta
     */
    backOfferPrice: {
      get() { return this.getBackOfferPrice },
      set(value) { this.setBackOfferPrice(value) },
    },
    /**
     * Indica si el producto es nuevo
     */
    isNewProduct() {
      return this.selectedType.text === 'Nuevo'
    },
  },
  watch: {
    priceToBeDecided() {
      if (this.priceToBeDecided) {
        this.minNormalPrice = '0'
        this.maxNormalPrice = '0'
        this.offerPrice = '0'
      } else {
        this.clearPrices()
      }
    },
    minNormalPrice(newValue) {
      if (this.isNewProduct) {
        this.backMinNormalPrice = newValue
      }
    },
    maxNormalPrice(newValue) {
      if (this.isNewProduct) {
        this.backMaxNormalPrice = newValue
      }
    },
    offerPrice(newValue) {
      if (this.isNewProduct) {
        this.backOfferPrice = newValue
      }
    },
  },
  methods: {
    ...mapActions({
      setMinNormalPrice: 'products/setMinNormalPrice',
      setMaxNormalPrice: 'products/setMaxNormalPrice',
      // Respaldo para los precios normales de lista
      setBackMinNormalPrice: 'products/setBackMinNormalPrice',
      setBackMaxNormalPrice: 'products/setBackMaxNormalPrice',
      // Banderas para el setting de precios o si habrá precios de mayorista
      setPriceToBeDecided: 'products/setPriceToBeDecided',
      // Precios de mayorista
      setMinWholesalerPrice: 'products/setMinWholesalerPrice',
      setMaxWholesalerPrice: 'products/setMaxWholesalerPrice',
      // Respaldo de los precios de mayorista
      setBackMinWholesalerPrice: 'products/setBackMinWholesalerPrice',
      setBackMaxWholesalerPrice: 'products/setBackMaxWholesalerPrice',
      // Precio de oferta
      setOfferPrice: 'products/setOfferPrice',
      // Respaldo del precio de oferta
      setBackOfferPrice: 'products/setBackOfferPrice',
      // Limpia los campos de precios de lista de todos los componentes
      clearPrices: 'products/clearPrices',
    }),
  },
}
</script>
