<template>
  <b-card>
    <b-card-text>
      <b-container>
        <b-row>
          <b-col md="6">
            <h4>Precios de lista</h4>
          </b-col>

          <!-- #region::Price to be decided checkbox -->
          <b-col
            md="6"
            class="d-flex"
          >
            <b-form-checkbox
              v-model="priceToBeDecided"
              class="ml-auto"
            >
              <strong>Precio por definir</strong>
            </b-form-checkbox>
          </b-col>
          <!-- #endregion::Price to be decided checkbox -->

          <!-- #region::L/F price input -->
          <b-col md="4">
            <TextInputWithValidation
              v-model="lfPrice"
              vid="lfPrice"
              rules="required|decimal|max_value:@drPrice"
              type="text"
              label="Precio L/F"
              name="precio l/f"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
              :disabled="priceToBeDecided"
            />
          </b-col>
          <!-- #endregion::L/F price input -->

          <!-- #region::D/R price input -->
          <b-col md="4">
            <TextInputWithValidation
              v-model="drPrice"
              vid="drPrice"
              rules="required|decimal|min_value:@lfPrice"
              type="text"
              label="Precio D/R"
              name="precio d/r"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
              :disabled="priceToBeDecided"
            />
          </b-col>
          <!-- #endregion::D/R price input -->

          <!-- #region::Suggest max price input -->
          <b-col md="4">
            <TextInputWithValidation
              v-model="suggestMaxPrice"
              vid="suggestMaxPrice"
              rules="required|decimal|min_value:@drPrice"
              type="text"
              label="Precio máximo"
              name="precio máximo"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
              :disabled="priceToBeDecided"
            />
          </b-col>
          <!-- #endregion::Suggest max price input -->

          <!-- #region::Offer price -->
          <b-col md="4">
            <TextInputWithValidation
              v-model="offerPrice"
              vid="offerPrice"
              rules="decimal|max_value:@drPrice"
              type="text"
              label="Precio oferta"
              name="precio oferta"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
              :disabled="priceToBeDecided"
            />
          </b-col>
          <!-- #endregion::Offer price -->

        </b-row>
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BFormCheckbox, BCard, BCardText, BContainer, BRow, BCol,
} from 'bootstrap-vue'

import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BContainer,
    BFormCheckbox,
    TextInputWithValidation,
  },
  computed: {
    ...mapGetters({
      getLfPrice: 'products/getLfPrice',
      getDrPrice: 'products/getDrPrice',
      getSuggestMaxPrice: 'products/getSuggestMaxPrice',
      // Respaldo de los precios de productos usados
      getBackLfPrice: 'products/getBackLfPrice',
      getBackDrPrice: 'products/getBackDrPrice',
      getBackSuggestMaxPrice: 'products/getBackSuggestMaxPrice',

      // Banderas para el setting de precios
      getPriceToBeDecided: 'products/getPriceToBeDecided',

      getSelectedType: 'products/getSelectedType',
      // Precio de oferta
      getOfferPrice: 'products/getOfferPrice',
      // Respaldo del precio de oferta
      getBackOfferPrice: 'products/getBackOfferPrice',
    }),
    selectedType: {
      get() { return this.getSelectedType },
    },
    /**
     * Precio L/F de lista
     */
    lfPrice: {
      get() { return this.getLfPrice },
      set(value) { this.setLfPrice(value) },
    },
    /**
     * Precio D/R de lista
     */
    drPrice: {
      get() { return this.getDrPrice },
      set(value) { this.setDrPrice(value) },
    },
    /**
     * Precio máximo sugerido
     */
    suggestMaxPrice: {
      get() { return this.getSuggestMaxPrice },
      set(value) { this.setSuggestMaxPrice(value) },
    },
    /**
     * Respaldo del precio L/F de lista
     */
    backLfPrice: {
      get() { return this.getBackLfPrice },
      set(value) { this.setBackLfPrice(value) },
    },
    /**
     * Respaldo del precio D/R de lista
     */
    backDrPrice: {
      get() { return this.getBackDrPrice },
      set(value) { this.setBackDrPrice(value) },
    },
    /**
     * Respaldo del precio máximo sugerido
     */
    backSuggestMaxPrice: {
      get() { return this.getBackSuggestMaxPrice },
      set(value) { this.setBackSuggestMaxPrice(value) },
    },
    /**
     * Indica si se establecen los precios de lista
     */
    priceToBeDecided: {
      get() { return this.getPriceToBeDecided },
      set(value) { this.setPriceToBeDecided(value) },
    },
    /**
     * Precio de oferta
     */
    offerPrice: {
      get() { return this.getOfferPrice },
      set(value) { this.setOfferPrice(value) },
    },
    /**
     * Respaldo del precio de oferta
     */
    backOfferPrice: {
      get() { return this.getBackOfferPrice },
      set(value) { this.setBackOfferPrice(value) },
    },
    /**
     * Indica si el producto es nuevo
     */
    isNewProduct() {
      return this.selectedType.text === 'Nuevo'
    },
  },
  watch: {
    priceToBeDecided() {
      if (this.priceToBeDecided) {
        this.lfPrice = '0'
        this.drPrice = '0'
        this.suggestMaxPrice = '0'
        this.offerPrice = '0'
      } else {
        this.clearPrices()
      }
    },
    lfPrice(newValue) {
      if (!this.isNewProduct) {
        this.backLfPrice = newValue
      }
    },
    drPrice(newValue) {
      if (!this.isNewProduct) {
        this.backDrPrice = newValue
      }
    },
    suggestMaxPrice(newValue) {
      if (!this.isNewProduct) {
        this.backSuggestMaxPrice = newValue
      }
    },
    offerPrice(newValue) {
      if (this.isNewProduct) {
        this.backOfferPrice = newValue
      }
    },
  },
  methods: {
    ...mapActions({
      setLfPrice: 'products/setLfPrice',
      setDrPrice: 'products/setDrPrice',
      setSuggestMaxPrice: 'products/setSuggestMaxPrice',
      // Respaldo de los precios de productos usados
      setBackLfPrice: 'products/setBackLfPrice',
      setBackDrPrice: 'products/setBackDrPrice',
      setPriceToBeDecided: 'products/setPriceToBeDecided',
      setBackSuggestMaxPrice: 'products/setBackSuggestMaxPrice',
      // Precio de oferta
      setOfferPrice: 'products/setOfferPrice',
      // Respaldo del precio de oferta
      setBackOfferPrice: 'products/setBackOfferPrice',
      // Limpia los campos de precios de lista de todos los componentes
      clearPrices: 'products/clearPrices',
    }),
  },
}
</script>
