import createNumberMask from 'text-mask-addons/dist/createNumberMask'

// eslint-disable-next-line import/prefer-default-export
export const currencyMask = createNumberMask({
  prefix: '',
  decimalSymbol: '.',
  allowNegative: false,
  thousandsSeparatorSymbol: ',',
  includeThousandsSeparator: true,
})
