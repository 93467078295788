<template>
  <b-card class="border border-dark shadow-none">
    <b-card-text>
      <b-container>
        <b-row
          class="mb-2"
        >
          <b-col
            md="4"
            class="mb-1"
          >
            <h4>Nuevas piezas</h4>
          </b-col>

          <!-- #region begin::Searcher -->
          <b-col md="12">
            <BasicSearcher
              :callback="handleSearch"
              placeholder="Buscar piezas"
            />
          </b-col>
          <!-- #endregion end::Searcher -->

          <b-col
            v-if="pieces.length === 0"
            md="12"
            class="text-center mt-1"
          >
            <p>
              <feather-icon
                icon="BoxIcon"
                class="feather-48"
              />
            </p>
            <span>Agrega piezas a este producto</span>
          </b-col>

          <b-col
            v-else
            md="12"
          >
            <b-table
              :items="pieces"
              :fields="columns"
              :per-page="perPage"
              :current-page="currentPage"
              responsive
              class="mt-2"
            >

              <!-- #region:Virtual column for tag -->
              <template #cell(Tag)="{ item }">
                <span class="text-nowrap">{{ item.Tag }}</span>
              </template>
              <!-- #endregion:Virtual column for tag -->

              <!-- #region:Virtual column for NoSeries -->
              <template #cell(NoSeries)="{ item }">
                <span class="text-nowrap">{{ item.NoSeries }}</span>
              </template>
              <!-- #endregion:Virtual column for NoSeries -->

              <!-- #region:Virtual column for NoPetition -->
              <template #cell(NoPetition)="{ item }">
                <span class="text-nowrap">{{ item.NoPetition }}</span>
              </template>
              <!-- #endregion:Virtual column for NoPetition -->

              <!-- #region:Virtual column for NoInvoice -->
              <template #cell(NoInvoice)="{ item }">
                <span class="text-nowrap">{{ item.NoInvoice }}</span>
              </template>
              <!-- #endregion:Virtual column for NoInvoice -->

              <!-- #region:Virtual column for NoImport -->
              <template #cell(NoImport)="{ item }">
                <span class="text-nowrap">{{ item.NoImport }}</span>
              </template>
              <!-- #endregion:Virtual column for NoImport -->

              <!-- #region:Virtual column for Condition -->
              <template #cell(Condition)="{ item }">
                <span class="text-nowrap">{{ item.Condition }}</span>
              </template>
              <!-- #endregion:Virtual column for Condition -->

              <!-- #region:Virtual column for Availability -->
              <template #cell(Availability)="{ item }">
                <span class="text-nowrap">
                  <h6><b-badge
                    class="ava-label"
                    :variant="getAvailabilityStyle(item.Availability)"
                  >{{ item.Availability }}
                  </b-badge>
                  </h6>
                </span>
              </template>
              <!-- #endregion:Virtual column for Availability -->

              <!-- #region:Virtual column for Location -->
              <template #cell(Location)="{ item }">
                <span class="text-nowrap">{{ item.Location }}</span>
              </template>
              <!-- #endregion:Virtual column for Location -->

              <!-- #region:Virtual column for ArriveDate -->
              <template #cell(ArriveDate)="{ item }">
                <span class="text-nowrap">{{ item.ArriveDate | moment("DD/MM/YYYY") }}</span>
              </template>
              <!-- #endregion:Virtual column for ArriveDate -->

              <!-- #region:Virtual column for UnitPriceInvoiceUsd -->
              <template #cell(UnitPriceInvoiceUsd)="{ item }">
                <span class="text-nowrap">{{ item.UnitPriceInvoiceUsd | currencyWhite }}</span>
              </template>
              <!-- #endregion:Virtual column for UnitPriceInvoiceUsd -->

              <!-- #region:Virtual column for ExchangeRate -->
              <template #cell(ExchangeRate)="{ item }">
                <span class="text-nowrap">{{ item.ExchangeRate | currencyWhite }}</span>
              </template>
              <!-- #endregion:Virtual column for ExchangeRate -->

              <!-- #region:Virtual column for UnitPriceInvoiceMn -->
              <template #cell(UnitPriceInvoiceMn)="{ item }">
                <span class="text-nowrap">{{ item.UnitPriceInvoiceMn | currencyWhite }}</span>
              </template>
              <!-- #endregion:Virtual column for UnitPriceInvoiceMn -->

              <!-- #region:Virtual column for CostUtility -->
              <template #cell(CostUtility)="{ item }">
                <span class="text-nowrap">{{ item.CostUtility | currencyWhite }}</span>
              </template>
              <!-- #endregion:Virtual column for CostUtility -->

              <!-- #region:Virtual column for ProvidersPriceUsd -->
              <template #cell(ProvidersPriceUsd)="{ item }">
                <span class="text-nowrap">{{ item.ProvidersPriceUsd | currencyWhite }}</span>
              </template>
              <!-- #endregion:Virtual column for ProvidersPriceUsd -->

              <!-- #region:Virtual column for ProvidersCostingMn -->
              <template #cell(ProvidersCostingMn)="{ item }">
                <span class="text-nowrap">{{ item.ProvidersCostingMn | currencyWhite }}</span>
              </template>
              <!-- #endregion:Virtual column for ProvidersCostingMn -->

              <!-- #region::A virtual column for actions -->
              <template #cell(actions)="{ item }">

                <div class="d-flex">
                  <!-- #region::Update piece button -->
                  <b-button
                    v-b-tooltip.hover.top="'Editar'"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    @click="onUpdatePiece(item)"
                  >
                    <feather-icon icon="Edit3Icon" />
                  </b-button>
                  <!-- #endregion::Update piece button -->

                  <!-- #region::Delete piece button -->
                  <b-button
                    v-b-tooltip.hover.top="'Eliminar'"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    @click="onDeletePiece(item)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                <!-- #endregion::Delete piece button -->
                </div>

              </template>
              <!-- #endregion::A virtual column for actions -->

            </b-table>
          </b-col>
        </b-row>

        <!-- #region begin::Pagination & items per list -->
        <BasicPaginator
          v-if="pieces.length > 0"
          ref="paginator"
          class="mt-2"
          :callback="handleChangePagination"
          :total-rows="pieces.length"
        />
        <!-- #endregion end::Pagination & items per list -->
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BCol, BRow, BCard, BCardText, BContainer, BTable, BBadge, VBTooltip, BButton,
} from 'bootstrap-vue'

import BasicSearcher from '@/components/forms/BasicSearcher.vue'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BBadge,
    BTable,
    BButton,
    BCardText,
    BContainer,
    BasicSearcher,
    BasicPaginator,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      columns: [
        {
          key: 'Index',
          label: 'No.',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'Tag',
          label: 'Tag',
          thClass: this.$ability.can('read', 'Piece_tag') ? 'bg-light' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_tag') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'NoSeries',
          label: 'No. serie',
          thClass: this.$ability.can('read', 'Piece_serie') ? 'bg-light text-nowrap' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_serie') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'NoPetition',
          label: 'No. Pedimento',
          thClass: this.$ability.can('read', 'Piece_petition') ? 'bg-light text-nowrap' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_petition') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'NoInvoice',
          label: 'No. factura',
          thClass: this.$ability.can('read', 'Piece_invoice') ? 'bg-light text-nowrap' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_invoice') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'NoImport',
          label: 'No. importación',
          thClass: this.$ability.can('read', 'Piece_import') ? 'bg-light text-nowrap' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_import') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Condition',
          label: 'Condición',
          thClass: this.$ability.can('read', 'Piece_condition') ? 'bg-light' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_condition') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Availability',
          label: 'Disponibilidad',
          thClass: this.$ability.can('read', 'Piece_availability') ? 'bg-light' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_availability') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Location',
          label: 'Ubicación',
          thClass: this.$ability.can('read', 'Piece_location') ? 'bg-light' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_location') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'ArriveDate',
          label: 'Fecha de llegada',
          thClass: this.$ability.can('read', 'Piece_arrival_date') ? 'bg-light text-nowrap' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_arrival_date') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'UnitPriceInvoiceUsd',
          label: 'Precio unitario factura USD',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'ExchangeRate',
          label: 'Tipo de cambio',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'UnitPriceInvoiceMn',
          label: 'Precio unitario factura M/N',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'CostUtility',
          label: 'Costeo MN\'SC / Utilidad(5%) M/N',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'ProvidersPriceUsd',
          label: 'Precio proveedores USD',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'ProvidersCostingMn',
          label: 'Costeo proveedores M/N',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'actions',
          label: 'Acciones',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getPreSale: 'products/getPreSale',
      getGlobalDate: 'pieces/getGlobalDate',
      getLocalPieces: 'pieces/getLocalPieces',
      getEditablePiece: 'pieces/getEditablePiece',

    }),
    /**
     * Indica si todas las piezas tienen estado preventa
     */
    preSale: {
      get() { return this.getPreSale },
    },
    /**
     * Lista de piezas mostradas en la lista
     */
    pieces: {
      get() {
        return this.getLocalPieces.map((piece, index) => {
          this.$set(piece, 'Index', index + 1)
          return piece
        })
      },
      set(value) { this.setLocalPieces(value) },
    },
    /**
     * Indica la pieza que se carga en el formulario de pieza
     * y que puede ser editada
     */
    editablePiece: {
      get() { return this.getEditablePiece },
      set(value) { this.setEditablePiece(value) },
    },
    /**
     * Se almacena para actulizar la información del producto
     */
    globalDate: {
      get() { return this.getGlobalDate },
    },
  },
  watch: {
    preSale(newPreSale) {
      this.pieces.map(piece => {
        this.$set(piece, 'Availability', newPreSale ? 'Preventa' : 'Disponible')
        return piece
      })
    },
    globalDate(date) {
      this.pieces.map(piece => {
        this.$set(piece, 'ArriveDate', date)
        return piece
      })
    },
  },
  methods: {
    ...mapActions({
      setLocalPieces: 'pieces/setLocalPieces',
      deleteLocalPiece: 'pieces/deleteLocalPiece',
      setEditablePiece: 'pieces/setEditablePiece',
      setLocalSearchTerm: 'pieces/setLocalSearchTerm',
    }),
    handleSearch(searchTerm) {
      this.currentPage = 1
      this.setLocalSearchTerm(searchTerm)
    },
    getAvailabilityStyle(availability) {
      switch (availability) {
        case 'Disponible':
          return 'light-success'
        case 'No disponible':
          return 'light-danger'
        default:
          return 'light-primary'
      }
    },
    onUpdatePiece(piece) {
      const editablePiece = { ...piece }
      this.$set(editablePiece, 'type', 'local')
      window.scrollTo(0, 0)
      this.editablePiece = editablePiece
    },
    onDeletePiece(piece) {
      this.$swal({
        title: '¿Deseas continuar?',
        text: '¡Al eliminar la pieza, se afectará el inventario del producto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Sí, eliminar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteLocalPiece(piece)
          this.currentPage = 1
          this.$refs.paginator.resetCurrentPage()

          this.showToast(
            'Pieza eliminada',
            'La pieza ha sido eliminada correctamente.',
            'success',
          )
        }
      })
    },
    handleChangePagination(currentPage, perPage) {
      this.currentPage = currentPage
      this.perPage = perPage
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .feather-48{
    width: 48px;
    height: 48px;
  }
</style>
