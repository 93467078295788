<template>
  <div>
    <b-card class="mt-n2 mx-n2">
      <!-- #region #region::Card for main options -->
      <div>
        <MainOptions
          :title="id ? 'Actualizar producto' : 'Nuevo producto'"
          :save-button-text="id ? 'Guardar cambios' : 'Guardar producto'"
          model-home-route="products-home"
          :callback="saveProduct"
        />
      </div>
      <!-- #endregion::Card for main options -->
    </b-card>
    <b-container>
      <b-row class="justify-content-center">

        <!-- #region::Layout for column definitions -->
        <b-col
          xl="9"
          lg="10"
          md="12"
        >

          <template v-if="loadingDataForm">
            <BasicSkeleton />
            <BasicSkeleton height="800px" />
          </template>
          <template v-else>
            <b-tabs>

              <!-- #region::Producto information -->
              <b-tab
                title="Producto"
                active
              >

                <!-- #region::Form -->
                <validation-observer ref="productForm">
                  <b-form @submit="$event.preventDefault()">

                    <ProductInformation />

                    <ProductDescription />

                    <NewProductPricesList
                      v-if="isNewProduct && selectedType !== ''"
                      ref="newProductPricesList"
                    />

                    <UsedProductPricesList
                      v-else-if="isUsedProduct && selectedType !== ''"
                      ref="usedProductPricesList"
                    />

                    <RemanufacturedProductListPrices
                      v-else-if="selectedType !== ''"
                      ref="remanufacturedProductListPrices"
                    />

                    <ProductDimensions ref="productDimensions" />
                  </b-form>
                </validation-observer>
                <!-- #endregion::Form -->

              </b-tab>
              <!-- #endregion::Producto information -->

              <b-tab
                title="Piezas"
                :disabled="isProductVisible"
              >
                <PiecesForm />
              </b-tab>

            </b-tabs>
          </template>

        </b-col>
      <!-- #endregion::Layout for column definitions -->

      </b-row>
    </b-container>
  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions, mapState } from 'vuex'
import {
  ValidationObserver, localize, extend, decimal,
} from 'vee-validate'
import {
  required, min, integer, email, minValue, maxValue,
} from '@validations'
import {
  BRow, BCol, BContainer, BForm, VBTooltip, BTab, BTabs, BCard,
} from 'bootstrap-vue'

import { currencyMask } from '@/helpers/NumberMask'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import uploadImage from '@/helpers/uploadImage'
import MainOptions from '@/components/forms/MainOptions.vue'

import PiecesForm from '@/modules/production/products/components/form/PiecesForm.vue'
import ProductPrices from '@/modules/production/products/components/form/ProductPrices.vue'
import ProductDimensions from '@/modules/production/products/components/form/ProductDimensions.vue'
import ProductDescription from '@/modules/production/products/components/form/ProductDescription.vue'
import ProductInformation from '@/modules/production/products/components/form/ProductInformation.vue'
import NewProductPricesList from '@/modules/production/products/components/form/NewProductPricesList.vue'
import UsedProductPricesList from '@/modules/production/products/components/form/UsedProductPricesList.vue'
import RemanufacturedProductListPrices from '@/modules/production/products/components/form/RemanufacturedProductListPrices.vue'

import getError from '@/helpers/ErrorsHandler'
import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
// #endregion

export default {
  components: {
    BCol,
    BTab,
    BRow,
    BTabs,
    BCard,
    BForm,
    PiecesForm,
    BContainer,
    MainOptions,
    BasicSkeleton,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    VBTooltip,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    ProductPrices,
    ProductDimensions,
    ProductDescription,
    ProductInformation,
    NewProductPricesList,
    UsedProductPricesList,
    RemanufacturedProductListPrices,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    visible: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      // * 14/09/2022 - TAG: Validations
      min,
      email,
      decimal,
      integer,
      required,
      minValue,
      maxValue,

      // * 14/09/2022 - TAG: V-models form
      currencyMask,
      pieces: [],
      deletedPieces: [],
      nextTodoId: 1,

      // * 08/02/2023 - TAG: Product name suggestions
      limit: 10,
      selected: null,
      datasuggest: [],
    }
  },
  computed: {
    ...mapState({
      stateResponseBrand: state => state.brands.stateResponse,
      stateResponseProduct: state => state.products.stateResponse,
      isLoadingProducts: state => state.products.isLoadingProducts,
      stateResponseCategory: state => state.categories.stateResponse,
    }),
    ...mapGetters({
      // * 19/05/2023 - TAG: Información de catálogos

      getBrands: 'brands/getBrands',
      getTypes: 'products/getTypes',
      getWeights: 'products/getWeights',
      getSuggests: 'products/getSuggests',
      getSuppliers: 'suppliers/getSuppliers',
      getCategories: 'categories/getCategories',
      getPaintColors: 'products/getPaintColors',
      getTapestryColors: 'products/getTapestryColors',

      // * 19/05/2023 - TAG: Información del producto

      getName: 'products/getName',
      getItem: 'products/getItem',
      getModel: 'products/getModel',
      getImages: 'products/getImages',
      getImage01: 'products/getImage01',
      getImage02: 'products/getImage02',
      getImage03: 'products/getImage03',
      getSatCode: 'products/getSatCode',
      getLowCount: 'products/getLowCount',
      getBackItem: 'products/getBackItem',
      getIdProduct: 'products/getIdProduct',
      getSubmitted: 'products/getSubmitted',
      getDestination: 'products/getDestination',
      getDescription: 'products/getDescription',
      getInternalCode: 'products/getInternalCode',
      getPendingImage: 'products/getPendingImage',
      getImagesLoaded: 'products/getImagesLoaded',
      getSelectedType: 'products/getSelectedType',
      getSelectedBrand: 'products/getSelectedBrand',
      getSelectedPaint: 'products/getSelectedPaint',
      getFilteredOptions: 'products/getFilteredOptions',
      getLoadingDataForm: 'products/getLoadingDataForm',
      getSelectedCategory: 'products/getSelectedCategory',
      getSelectedTapestry: 'products/getSelectedTapestry',
      getSelectedSupplier: 'products/getSelectedSupplier',
      getCanceledTypeChange: 'products/getCanceledTypeChange',

      // * 19/05/2023 - TAG: Precios del producto

      getCostUtility: 'products/getCostUtility',
      getExchangeRate: 'products/getExchangeRate',
      getProvidersPriceUsd: 'products/getProvidersPriceUsd',
      getUnitPriceInvoiceMn: 'products/getUnitPriceInvoiceMn',
      getProvidersCostingMn: 'products/getProvidersCostingMn',
      getUnitPriceInvoiceUsd: 'products/getUnitPriceInvoiceUsd',

      // ? 19/05/2023 - TAG: La bandera para el precio a decidir es utilizada en los
      // ? componentes de precios de lista

      getPriceToBeDecided: 'products/getPriceToBeDecided',

      // * 19/05/2023 - TAG: Precios de lista de productos nuevos/remanufacturados

      getMinNormalPrice: 'products/getMinNormalPrice',
      getMaxNormalPrice: 'products/getMaxNormalPrice',
      getWholesalerPrice: 'products/getWholesalerPrice',
      getMinWholesalerPrice: 'products/getMinWholesalerPrice',
      getMaxWholesalerPrice: 'products/getMaxWholesalerPrice',

      // * 19/05/2023 - TAG: Precios de lista de productos usados

      getLfPrice: 'products/getLfPrice',
      getDrPrice: 'products/getDrPrice',
      getSuggestMaxPrice: 'products/getSuggestMaxPrice',

      // * 04/09/2023 - TAG: Precio de oferta
      getOfferPrice: 'products/getOfferPrice',

      // * 19/05/2023 - TAG: Dimensiones del producto

      getWidth: 'products/getWidth',
      getHeight: 'products/getHeight',
      getWeight: 'products/getWeight',
      getLength: 'products/getLength',
      getSelectedWeight: 'products/getSelectedWeight',

      // * 22/05/2023 - TAG: Condiciones de las piezas en estado preventa

      getPreSale: 'products/getPreSale',
      getGlobalDate: 'pieces/getGlobalDate',
      getSelectedDate: 'products/getSelectedDate',
      getAvailablePieces: 'pieces/getAvailablePieces',

      // * 22/05/2023 - TAG: Listas de piezas

      getPiecesCount: 'pieces/getPiecesCount',
      getLocalPieces: 'pieces/getLocalPieces',
      getPiecesToUpdate: 'pieces/getPiecesToUpdate',
      getPiecesToDelete: 'pieces/getPiecesToDelete',

      // * 06/06/2023 - TAG: Representa la lista de piezas de un producto cuando todas
      // * las piezas de este estan agregadas a un circuito
      getCountPiecesQuery: 'pieces/getCountPiecesQuery',
      getProductPiecesQuery: 'pieces/getProductPiecesQuery',
    }),

    // * 19/05/2023 - TAG: Información del producto

    /**
     * Nombre del producto
     */
    name: {
      get() { return this.getName },
      set(value) { this.setName(value) },
    },
    /**
     * Item que se establece cuando el producto es de tipo nuevo
     */
    item: {
      get() { return this.getItem },
      set(value) { this.setItem(value) },
    },
    /**
     * Modelo del producto
     */
    model: {
      get() { return this.getModel },
      set(value) { this.setModel(value) },
    },
    /**
     * Imagenes del producto
     */
    images: {
      get() { return this.getImages },
      set(value) { this.setImages(value) },
    },
    /**
     * Imagenes del producto
     */
    image01: {
      get() { return this.getImage01 },
      set(value) { this.setImage01(value) },
    },
    /**
     * Imagenes del producto
     */
    image02: {
      get() { return this.getImage02 },
      set(value) { this.setImage02(value) },
    },
    /**
     * Imagenes del producto
     */
    image03: {
      get() { return this.getImage03 },
      set(value) { this.setImage03(value) },
    },
    /**
     * Código SAT del producto
     */
    satCode: {
      get() { return this.getSatCode },
      set(value) { this.setSatCode(value) },
    },
    /**
     * Cantidad mínima para bajo inventario del producto
     */
    lowCount: {
      get() { return this.getLowCount },
      set(value) { this.setLowCount(value) },
    },
    /**
     * Indentificador del producto
     */
    idProduct: {
      get() { return this.getIdProduct },
      set(value) { this.setIdProduct(value) },
    },
    /**
     * Verifica el submit del formulario
     */
    submitted: {
      get() { return this.getSubmitted },
      set(value) { this.setSubmitted(value) },
    },
    /**
     * Destino del producto
     */
    destination: {
      get() { return this.getDestination },
      set(value) { this.setDestination(value) },
    },
    /**
     * Descripción del producto
     */
    description: {
      get() { return this.getDescription },
      set(value) { this.setDescription(value) },
    },
    /**
     * Código interno del producto
     */
    internalCode: {
      get() { return this.getInternalCode },
      set(value) { this.setInternalCode(value) },
    },
    /**
     * Indica si se han cargado imágenes
     */
    imagesLoaded: {
      get() { return this.getImagesLoaded },
      set(value) { this.setImagesLoaded(value) },
    },
    /**
    * Tipo del producto seleccionado
    */
    selectedType: {
      get() { return this.getSelectedType },
      set(value) { this.setSelectedType(value) },
    },
    /**
     * Marca de producto seleccionada
     */
    selectedBrand: {
      get() { return this.getSelectedBrand },
      set(value) { this.setSelectedBrand(value) },
    },
    /**
     * Color de producto seleccionado
     */
    selectedPaint: {
      get() { return this.getSelectedPaint },
      set(value) { this.setSelectedPaint(value) },
    },
    /**
     * Categoría del producto seleccionada
     */
    selectedCategory: {
      get() { return this.getSelectedCategory },
      set(value) { this.setSelectedCategory(value) },
    },
    /**
     * Color de tapicería del producto seleccionada
     */
    selectedTapestry: {
      get() { return this.getSelectedTapestry },
      set(value) { this.setSelectedTapestry(value) },
    },
    /**
     * Proveedor del producto seleccionado
     */
    selectedSupplier: {
      get() { return this.getSelectedSupplier },
      set(value) { this.setSelectedSupplier(value) },
    },
    /**
     * Indica si alguna información del producto está haciendo
     * atendida con el servidor. Actualizacion o carga de información
     */
    loadingDataForm: {
      get() { return this.getLoadingDataForm },
      set(value) { this.setLoadingDataForm(value) },
    },

    // * 19/05/2023 - TAG: Precios del producto

    /**
     * Costo de utilidad
     */
    costUtility: {
      get() { return this.getCostUtility },
      set(value) { this.setCostUtility(value) },
    },
    /**
     * Tipo de cambio
     */
    exchangeRate: {
      get() { return this.getExchangeRate },
      set(value) { this.setExchangeRate(value) },
    },
    /**
     * Precio en dolares para proveedores
     */
    providersPriceUsd: {
      get() { return this.getProvidersPriceUsd },
      set(value) { this.setProvidersPriceUsd(value) },
    },
    /**
     * Precio unitario de facturación en M/N
     */
    unitPriceInvoiceMn: {
      get() { return this.getUnitPriceInvoiceMn },
      set(value) { this.setUnitPriceInvoiceMn(value) },
    },
    /**
     * Costo para proveedores en M/N
     */
    providersCostingMn: {
      get() { return this.getProvidersCostingMn },
      set(value) { this.setProvidersCostingMn(value) },
    },
    /**
     * Precio unitario de facturación en M/N
     */
    unitPriceInvoiceUsd: {
      get() { return this.getUnitPriceInvoiceUsd },
      set(value) { this.setUnitPriceInvoiceUsd(value) },
    },

    // * 19/05/2023 - TAG: Precios de lista de productos nuevos/remanufacturados

    /**
     * Precio de lista normal mínimo
     */
    minNormalPrice: {
      get() { return this.getMinNormalPrice },
      set(value) { this.setMinNormalPrice(value) },
    },
    /**
     * Precio de lista normal máximo
     */
    maxNormalPrice: {
      get() { return this.getMaxNormalPrice },
      set(value) { this.setMaxNormalPrice(value) },
    },
    /**
     * Indica si se establecen los precios de lista
     */
    priceToBeDecided: {
      get() { return this.getPriceToBeDecided },
      set(value) { this.setPriceToBeDecided(value) },
    },
    /**
     * Indica si el producto cuenta con precios para mayoristas
     */
    wholesalerPrice: {
      get() { return this.getWholesalerPrice },
      set(value) { this.setWholesalerPrice(value) },
    },
    /**
     * Precio mínimo de lista para mayorista
     */
    minWholesalerPrice: {
      get() { return this.getMinWholesalerPrice },
      set(value) { this.setMinWholesalerPrice(value) },
    },
    /**
     * Precio máximo de lista para mayorista
     */
    maxWholesalerPrice: {
      get() { return this.getMaxWholesalerPrice },
      set(value) { this.setMaxWholesalerPrice(value) },
    },

    // * 19/05/2023 - TAG: Precios de lista de productos usados

    /**
     * Precio L/F de lista
     */
    lfPrice: {
      get() { return this.getLfPrice },
      set(value) { this.setLfPrice(value) },
    },
    /**
     * Precio D/R de lista
     */
    drPrice: {
      get() { return this.getDrPrice },
      set(value) { this.setDrPrice(value) },
    },
    /**
     * Precio máximo sugerido
     */
    suggestMaxPrice: {
      get() { return this.getSuggestMaxPrice },
      set(value) { this.setSuggestMaxPrice(value) },
    },
    /**
     * Precio de oferta
     */
    offerPrice: {
      get() { return this.getOfferPrice },
      set(value) { this.setOfferPrice(value) },
    },

    // * 19/05/2023 - TAG: Dimensiones del producto

    /**
     * Anchura del producto
     */
    width: {
      get() { return this.getWidth },
      set(value) { this.setWidth(value) },
    },
    /**
     * Altura del producto
     */
    height: {
      get() { return this.getHeight },
      set(value) { this.setHeight(value) },
    },
    /**
     * Peso del producto
     */
    weight: {
      get() { return this.getWeight },
      set(value) { this.setWeight(value) },
    },
    /**
     * Largo del producto
     */
    length: {
      get() { return this.getLength },
      set(value) { this.setLength(value) },
    },
    /**
     * Tipo del peso seleccionado
     */
    selectedWeight: {
      get() { return this.getSelectedWeight },
      set(value) { this.setSelectedWeight(value) },
    },

    // * 22/05/2023 - TAG: Condiciones de las piezas en estado preventa

    /**
     * Se almacena para actulizar la información del producto
     */
    globalDate: {
      get() { return this.getGlobalDate },
    },
    /**
     * Se aplica a todas las piezas cuando se activa el estado preventa
     */
    selectedDate: {
      get() { return this.getSelectedDate },
      set(value) { this.setSelectedDate(value) },
    },
    /**
     * Indica si todas las piezas tienen estado preventa
     */
    preSale: {
      get() { return this.getPreSale },
      set(value) { this.setPreSale(value) },
    },

    /**
     * Indica si todas las piezas tienen estado de disponibilidad
     * en Disponible
     */
    availablePieces: {
      get() { return this.getAvailablePieces },
    },

    // * 22/05/2023 - TAG: Listas de piezas

    /**
     * Indica la cantidad total de piezas
     */
    piecesCount: {
      get() { return this.getPiecesCount },
    },

    /**
     * Nuevas piezas creadas
     */
    localPieces: {
      get() { return this.getLocalPieces },
    },

    /**
     * Piezas que seran actualizadas
     */
    piecesToUpdate: {
      get() { return this.getPiecesToUpdate },
    },

    /**
     * Piezas que seran elminadas
     */
    piecesToDelete: {
      get() { return this.getPiecesToDelete },
    },

    /**
     * Cantidad de piezas que se muestran en el detalle de producto
     * cuando todas las piezas esta agregadas a un circuito
     */
    countPiecesQuery: {
      get() { return this.getCountPiecesQuery },
    },

    /**
     * Query necesario para cargar las piezas en el detalle de producto
     * cuando todas las piezas esta agregadas a un circuito
     */
    productPiecesQuery: {
      get() { return this.getProductPiecesQuery },
    },
    /**
     * Indica si hay una imagen pendiente
     */
    pendingImage: {
      get() { return this.getPendingImage },
      set(value) { this.setPendingImage(value) },
    },
    /**
     * Indica si el producto es nuevo
     */
    isNewProduct() {
      return this.selectedType.text === 'Nuevo'
    },
    /**
     * Indica si el producto es usado
     */
    isUsedProduct() {
      return this.selectedType.text === 'Usado'
    },
    /**
     * Determina si el producto viene desde el detalle de producto de circuito. Cuando todas
     * las piezas del producto estan agregadas a este
     */
    isProductVisible() {
      return this.visible === 1 || this.visible === '1'
    },
  },
  watch: {
    item(newValue) {
      if (this.isNewProduct) {
        this.backItem = newValue
      }
    },
    minNormalPrice(newValue) {
      if (this.isNewProduct) {
        this.backMinNormalPrice = newValue
      }
    },
    maxNormalPrice(newValue) {
      if (this.isNewProduct) {
        this.backMaxNormalPrice = newValue
      }
    },
    minWholesalerPrice(newValue) {
      if (this.isNewProduct) {
        this.backMinWholesalerPrice = newValue
      }
    },
    maxWholesalerPrice(newValue) {
      if (this.isNewProduct) {
        this.backMaxWholesalerPrice = newValue
      }
    },
    lfPrice(newValue) {
      if (!this.isNewProduct) {
        this.backLfPrice = newValue
      }
    },
    drPrice(newValue) {
      if (!this.isNewProduct) {
        this.backDrPrice = newValue
      }
    },
    suggestMaxPrice(newValue) {
      if (!this.isNewProduct) {
        this.backSuggestMaxPrice = newValue
      }
    },
  },
  async created() {
    try {
      this.loadingDataForm = true
      this.$swal({
        title: 'Espere por favor',
        allowOutsideClick: false,
      })

      this.$swal.showLoading()

      // * 15/09/2022 - TAG: Set location language for validation messages
      localize('es')

      // * 15/09/2022 - TAG: Load data to fill form select components
      await this.loadSuggests()
      await this.loadPaintColors()
      await this.loadTapestryColors()
      await this.loadBrandsWithoutPagination(true)
      await this.loadSuppliersWithoutPagination(true)
      await this.loadCategoriesWithoutPagination(true)
      await this.loadProcategories()

      if (this.id) {
        const response = await this.loadProduct64({ id: this.id, visible: this.visible })
        this.loadExistingProduct(response.data.data.product)
        this.idProduct = this.id
      }

      extend('min_value', {
        params: ['target'],
        validate(value, { target }) {
          return Number(value) >= Number(target)
        },
        message: 'El campo {_field_} debe ser mayor o igual a {target}',
      })

      extend('max_value', {
        params: ['target'],
        validate(value, { target }) {
          return Number(value) <= Number(target)
        },
        message: 'El campo {_field_} debe ser menor o igual a {target}',
      })
    } catch (error) {
      this.showToast('Error de validación', getError(error), 'danger')
    } finally {
      this.$swal.close()
      this.loadingDataForm = false
    }
  },

  /**
  * Hook de la eliminación de componente
  *
  * @summary Limpia toda la informacion del producto y de las piezas
  */
  beforeDestroy() {
    this.clearPiecesInfo()
    this.clearProductInfo()
  },
  methods: {
    ...mapActions({
      // * 19/05/2023 - TAG: Acciones para crear y actualizar modelos

      createBrand: 'brands/createBrand',
      createProduct: 'products/createProduct',
      updateProduct: 'products/updateProduct',
      createCategory: 'categories/createCategory',
      saveProductImages: 'products/saveProductImages',

      // * 19/05/2023 - TAG: Acciones para cargar catálogos y modelos

      loadProduct64: 'products/loadProduct64',
      loadSuggests: 'products/loadSuggests',
      loadPaintColors: 'products/loadPaintColors',
      loadTapestryColors: 'products/loadTapestryColors',
      loadBrandsWithoutPagination: 'brands/loadBrandsWithoutPagination',
      loadSuppliersWithoutPagination: 'suppliers/loadSuppliersWithoutPagination',
      loadCategoriesWithoutPagination: 'categories/loadCategoriesWithoutPagination',

      // * 19/05/2023 - TAG: Acciones para la gestión general de la información del producto

      setName: 'products/setName',
      setItem: 'products/setItem',
      setModel: 'products/setModel',
      setImages: 'products/setImages',
      setImage01: 'products/setImage01',
      setImage02: 'products/setImage02',
      setImage03: 'products/setImage03',
      setSatCode: 'products/setSatCode',
      setLowCount: 'products/setLowCount',
      setIdProduct: 'products/setIdProduct',
      setSubmitted: 'products/setSubmitted',
      setDestination: 'products/setDestination',
      setDescription: 'products/setDescription',
      setInternalCode: 'products/setInternalCode',
      setImagesLoaded: 'products/setImagesLoaded',
      setPendingImage: 'products/setPendingImage',
      setSelectedType: 'products/setSelectedType',
      setSelectedBrand: 'products/setSelectedBrand',
      setSelectedPaint: 'products/setSelectedPaint',
      setFilteredOptions: 'products/setFilteredOptions',
      setLoadingDataForm: 'products/setLoadingDataForm',
      setSelectedCategory: 'products/setSelectedCategory',
      setSelectedTapestry: 'products/setSelectedTapestry',
      setSelectedSupplier: 'products/setSelectedSupplier',
      setCanceledTypeChange: 'products/setCanceledTypeChange',

      // * 19/05/2023 - TAG: Acciones para los precios del producto

      setCostUtility: 'products/setCostUtility',
      setExchangeRate: 'products/setExchangeRate',
      setProvidersPriceUsd: 'products/setProvidersPriceUsd',
      setUnitPriceInvoiceMn: 'products/setUnitPriceInvoiceMn',
      setProvidersCostingMn: 'products/setProvidersCostingMn',
      setUnitPriceInvoiceUsd: 'products/setUnitPriceInvoiceUsd',

      // ? 19/05/2023 - TAG: La bandera para el precio a decidir es utilizada en los
      // ? componentes de precios de lista

      setPriceToBeDecided: 'products/setPriceToBeDecided',

      // * 19/05/2023 - TAG: Precios de lista de productos nuevos/remanufacturados

      setMinNormalPrice: 'products/setMinNormalPrice',
      setMaxNormalPrice: 'products/setMaxNormalPrice',
      setWholesalerPrice: 'products/setWholesalerPrice',
      setMinWholesalerPrice: 'products/setMinWholesalerPrice',
      setMaxWholesalerPrice: 'products/setMaxWholesalerPrice',

      // * 19/05/2023 - TAG: Precios de lista de productos usados

      setLfPrice: 'products/setLfPrice',
      setDrPrice: 'products/setDrPrice',
      setSuggestMaxPrice: 'products/setSuggestMaxPrice',

      // * 04/09/2023 - TAG: Precio de oferta
      setOfferPrice: 'products/setOfferPrice',

      // * 19/05/2023 - TAG: Dimensiones del producto

      setWidth: 'products/setWidth',
      setHeight: 'products/setHeight',
      setWeight: 'products/setWeight',
      setLength: 'products/setLength',
      setSelectedWeight: 'products/setSelectedWeight',

      // * 22/05/2023 - TAG: Condiciones de las piezas en estado preventa

      setPreSale: 'products/setPreSale',
      setSelectedDate: 'products/setSelectedDate',

      clearPiecesInfo: 'pieces/clearPiecesInfo',
      clearProductInfo: 'products/clearProductInfo',

      loadPiecesProduct: 'pieces/loadPiecesProduct',

      /**
       * Se utiliza para cargar el select de procategorías
       */
      loadProcategories: 'procategories/loadProcategories',
    }),

    /**
    * Almacena un nuevo producto
    *
    * @summary Accede a la informacion de los componentes del formulario y a la lista de piezas
    * para armar la petición que irá al servidor y creará un nuevo producto
    * @exception Retorna un error en caso de que exista falla en la creación del objeto de petición
    * o si la respuesta del servidor es negativa
    */
    async saveProduct() {
      try {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const success = await this.$refs.productForm.validate()
        this.submitted = true

        if (!this.pendingImage && (!this.image01 && !this.image02 && !this.image03)) {
          this.showToast(
            'Error de validación',
            'Es necesario agregar al menos una imagen.',
            'danger',
          )
          this.imagesLoaded = false
          return
        }

        this.imagesLoaded = true

        if (this.lowCount > this.piecesCount) {
          this.showToast(
            'Error de validación',
            'La cantidad de bajo inventario no debe ser mayor a la cantidad del producto.',
            'danger',
          )

          return
        }

        if (success) {
          const formData = new FormData()

          if (this.id) {
            formData.append('id', this.id)
          }

          formData.append('name', this.name)
          formData.append('item', this.item)
          formData.append('model', this.model)
          formData.append('qty', this.piecesCount)
          formData.append('code', this.internalCode)
          formData.append('type', this.selectedType.text)
          formData.append('keyCodeSat', this.satCode)
          formData.append('destiny', this.destination)
          formData.append('description', this.description)
          formData.append('idBrand', this.selectedBrand.IdBrand)
          formData.append('idPaint', this.selectedPaint.IdColor)
          formData.append('qtyLowInventory', this.lowCount)
          formData.append('idCategory', this.selectedCategory.IdCategory)
          formData.append('idTapestry', this.selectedTapestry.IdColor)
          formData.append('idSupplier', this.selectedSupplier ? this.selectedSupplier.IdSupplier : '')
          formData.append('pricesDefined', this.priceToBeDecided ? 1 : 0)
          formData.append('pricesDefinedWholesaler', this.wholesalerPrice ? 1 : 0)

          formData.append('priceLf', this.lfPrice || 0)
          formData.append('priceDr', this.drPrice || 0)
          formData.append('maxSuggestedPrice', this.suggestMaxPrice || 0)

          formData.append('priceMin', this.minNormalPrice)
          formData.append('priceMax', this.maxNormalPrice)

          formData.append('priceMinWholesaler', this.minWholesalerPrice || 0)
          formData.append('priceMaxWholesaler', this.maxWholesalerPrice || 0)

          formData.append('offerPrice', this.offerPrice || 0)

          formData.append('tc', this.exchangeRate)
          formData.append('ccost', this.costUtility)
          formData.append('providersPriceUsd', this.providersPriceUsd)
          formData.append('unitPriceInvoiceMn', this.unitPriceInvoiceMn)
          formData.append('providersCostingMn', this.providersCostingMn)
          formData.append('unitPriceInvoiceUsd', this.unitPriceInvoiceUsd)

          // Values from productDimentions component
          formData.append('heigh', this.$refs.productDimensions.height)
          formData.append('width', this.$refs.productDimensions.width)
          formData.append('weight', this.$refs.productDimensions.weight)
          formData.append('length', this.$refs.productDimensions.length)
          formData.append('typeWeight', this.$refs.productDimensions.selectedWeight.value)

          formData.append('preSale', this.preSale ? 1 : 0)
          formData.append('availablePieces', this.availablePieces ? 1 : 0)
          formData.append('arrivalDatePresale', this.selectedDate)
          formData.append('globalDate', this.globalDate)
          formData.append('pendingImage', this.pendingImage)

          this.localPieces.forEach(piece => {
            const pieceData = this.pieceData(piece)
            formData.append('newPieces[]', pieceData)
          })

          this.piecesToUpdate.forEach(piece => {
            const pieceData = this.pieceData(piece)
            formData.append('updatePieces[]', pieceData)
          })

          this.piecesToDelete.forEach(piece => {
            const pieceData = this.pieceData(piece)
            formData.append('deletePieces[]', pieceData)
          })

          const imagesFormData = new FormData()
          if (this.image01) {
            imagesFormData.append('files[]', this.image01)
          }

          if (this.image02) {
            imagesFormData.append('files[]', this.image02)
          }

          if (this.image03) {
            imagesFormData.append('files[]', this.image03)
          }

          let response = null

          // eslint-disable-next-line no-restricted-syntax
          // for (const pair of formData.entries()) {
          //   console.log(`${pair[0]}, ${pair[1]}`)
          // }

          // eslint-disable-next-line no-restricted-syntax
          // for (const pair of imagesFormData.entries()) {
          //   console.log(`${pair[0]}, ${pair[1]}`)
          // }

          if (this.id) {
            response = await this.updateProduct(formData)
            if (!this.pendingImage) {
              imagesFormData.append('idProduct', this.id)
              await uploadImage(imagesFormData, 'update')
            }
          } else {
            response = await this.createProduct(formData)
            if (!this.pendingImage) {
              imagesFormData.append('idProduct', response.data.data)
              await uploadImage(imagesFormData, 'save')
            }
          }

          if (this.stateResponseProduct.success) {
            if (this.id) {
              this.$swal.fire('Guardado', '¡El producto ha sido actualizado correctamente!', 'success')

              if (this.isProductVisible) {
                this.$router.push({
                  name: 'product-details-pieces',
                  params: {
                    /**
                   * Identificador del producto
                   */
                    id: this.id,
                    /**
                   * Arreglo de identificadores de piezas que se muestran
                   * en el detalle del producto
                   */
                    query: this.productPiecesQuery,
                    /**
                   * Indica la cantidad de piezas que se deben mostrar
                   */
                    count: this.countPiecesQuery,
                    /**
                   * Valida el estado del producto que puede ser mostrado o no
                   * en el detalle del producto
                   */
                    visible: 1,
                  },
                })
              } else {
                this.$router.push({ name: 'product-details', params: { id: this.id } })
              }
            } else {
              this.$swal.fire('Guardado', '¡El producto ha sido registrado correctamente!', 'success')
              this.$router.push({ name: 'product-details', params: { id: response.data.data } })
            }
          } else {
            this.showSweetAlert('¡Ups!', this.stateResponseProduct.message, 'error')
          }
        } else {
          this.showToast(
            'Error de validación',
            'Se encontraron uno o más errores en la validación del formulario del producto.',
            'danger',
          )
        }
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },

    /**
    * Carga un producto existente
    *
    * @summary Establece todas las propiedades del producto en los componentes
    * editables del formulario
    * @param {object} product - Contiene la información del producto
    */
    loadExistingProduct(product) {
      this.name = product.Name
      this.item = product.Item
      this.model = product.Model
      // this.realCount = product.Qty
      this.internalCode = product.Code

      const type = this.getTypes.find(element => element.text === product.Type)
      this.selectedType = type

      this.satCode = product.keyCodeSat
      this.destination = product.Destiny
      this.description = product.Description
      this.lowCount = product.QtyLowInventory
      this.priceToBeDecided = product.PricesDefined === 1
      this.wholesalerPrice = product.PricesDefinedWholesaler === 1

      const brand = this.getBrands.find(element => element.IdBrand === product.FK_IdBrand)
      this.selectedBrand = brand

      const paint = this.getPaintColors.find(element => element.IdColor === product.FK_IdPaint)
      this.selectedPaint = paint

      const category = this.getCategories.find(element => element.IdCategory === product.FK_IdCategory)
      this.selectedCategory = category

      const tapestry = this.getTapestryColors.find(element => element.IdColor === product.FK_IdTapestry)
      this.selectedTapestry = tapestry

      const supplier = this.getSuppliers.find(element => element.IdSupplier === product.FK_IdSupplier)
      this.selectedSupplier = supplier

      // Se establecen los precios de lista para productos usados
      this.lfPrice = this.priceToBeDecided ? '0' : product.PriceLf
      this.drPrice = this.priceToBeDecided ? '0' : product.PriceDr
      this.suggestMaxPrice = this.priceToBeDecided ? '0' : product.MaxSuggestedPrice

      this.minNormalPrice = this.priceToBeDecided ? '0' : product.PriceMin
      this.maxNormalPrice = this.priceToBeDecided ? '0' : product.PriceMax

      // Precios de mayorista
      this.minWholesalerPrice = this.priceToBeDecided ? '0' : product.PriceMinWholesaler
      this.maxWholesalerPrice = this.priceToBeDecided ? '0' : product.PriceMaxWholesaler

      // Precio de oferta
      this.offerPrice = this.priceToBeDecided ? '0' : product.OfferPrice

      // Se establecen los precios del producto
      this.exchangeRate = product.Tc
      this.costUtility = product.Ccost
      this.providersPriceUsd = product.ProvidersPriceUsd
      this.unitPriceInvoiceMn = product.UnitPriceInvoiceMn
      this.providersCostingMn = product.ProvidersCostingMn
      this.unitPriceInvoiceUsd = product.UnitPriceInvoiceUsd

      // Se establecen las dimensiones del producto
      this.width = product.Width
      this.height = product.Heigh
      this.weight = product.Weight
      this.length = product.Length

      const weight = this.getWeights.find(element => element.value === product.TypeWeight)
      this.selectedWeight = weight

      this.preSale = !!product.PreSale
      this.selectedDate = this.preSale ? (product.ArrivalDatePresale || '') : ''

      this.pieces = product.pieces
      if (product.images.full) {
        const [img01, img02, img03] = product.images.full
        this.image01 = img01
        this.image02 = img02
        this.image03 = img03
        this.pendingImage = false
      } else {
        this.pendingImage = true
      }
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },

    /**
    * Muestra un mensaje de tipo alert
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} icon - Icono mostrado en el mensaje
    */
    showSweetAlert(title, text, icon) {
      this.$swal({
        title,
        text,
        icon,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    /**
    * Pieza para enviar al servidor
    *
    * @summary Convierte la informacion de las piezas nuevas a objetos en formato JSON
    * que son enviados al servidor
    * @param {object} piece - Información de la pieza
    * @return {object} Información de la pieza en formato JSON
    */
    pieceData(piece) {
      const pieceData = JSON.stringify({
        ...(piece.IdPiece && { idPiece: piece.IdPiece }),
        tag: piece.Tag,
        noSeries: piece.NoSeries,
        arriveDate: piece.ArriveDate,
        noImport: piece.NoImport,
        noInvoice: piece.NoInvoice,
        noPetition: piece.NoPetition,
        location: piece.Location,
        condition: piece.Condition,
        availability: piece.Availability,

        tc: piece.ExchangeRate,
        ccost: piece.CostUtility,
        providersPriceUsd: piece.ProvidersPriceUsd,
        unitPriceInvoiceMn: piece.UnitPriceInvoiceMn,
        providersCostingMn: piece.ProvidersCostingMn,
        unitPriceInvoiceUsd: piece.UnitPriceInvoiceUsd,
      })

      return pieceData
    },

    // activateTab(newTabIndex) {
    //   if (newTabIndex === 0) {
    //     this.$refs.productForm.validate()
    //   }
    // },
  },
}
</script>
