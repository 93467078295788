var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"border border-dark shadow-none"},[_c('b-card-text',[_c('b-container',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"mb-1",attrs:{"md":"4"}},[_c('h4',[_vm._v("Nuevas piezas")])]),_c('b-col',{attrs:{"md":"12"}},[_c('BasicSearcher',{attrs:{"callback":_vm.handleSearch,"placeholder":"Buscar piezas"}})],1),(_vm.pieces.length === 0)?_c('b-col',{staticClass:"text-center mt-1",attrs:{"md":"12"}},[_c('p',[_c('feather-icon',{staticClass:"feather-48",attrs:{"icon":"BoxIcon"}})],1),_c('span',[_vm._v("Agrega piezas a este producto")])]):_c('b-col',{attrs:{"md":"12"}},[_c('b-table',{staticClass:"mt-2",attrs:{"items":_vm.pieces,"fields":_vm.columns,"per-page":_vm.perPage,"current-page":_vm.currentPage,"responsive":""},scopedSlots:_vm._u([{key:"cell(Tag)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.Tag))])]}},{key:"cell(NoSeries)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.NoSeries))])]}},{key:"cell(NoPetition)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.NoPetition))])]}},{key:"cell(NoInvoice)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.NoInvoice))])]}},{key:"cell(NoImport)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.NoImport))])]}},{key:"cell(Condition)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.Condition))])]}},{key:"cell(Availability)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_c('h6',[_c('b-badge',{staticClass:"ava-label",attrs:{"variant":_vm.getAvailabilityStyle(item.Availability)}},[_vm._v(_vm._s(item.Availability)+" ")])],1)])]}},{key:"cell(Location)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.Location))])]}},{key:"cell(ArriveDate)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("moment")(item.ArriveDate,"DD/MM/YYYY")))])]}},{key:"cell(UnitPriceInvoiceUsd)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currencyWhite")(item.UnitPriceInvoiceUsd)))])]}},{key:"cell(ExchangeRate)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currencyWhite")(item.ExchangeRate)))])]}},{key:"cell(UnitPriceInvoiceMn)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currencyWhite")(item.UnitPriceInvoiceMn)))])]}},{key:"cell(CostUtility)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currencyWhite")(item.CostUtility)))])]}},{key:"cell(ProvidersPriceUsd)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currencyWhite")(item.ProvidersPriceUsd)))])]}},{key:"cell(ProvidersCostingMn)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currencyWhite")(item.ProvidersCostingMn)))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Editar'),expression:"'Editar'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.onUpdatePiece(item)}}},[_c('feather-icon',{attrs:{"icon":"Edit3Icon"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Eliminar'),expression:"'Eliminar'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.onDeletePiece(item)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1)]}}])})],1)],1),(_vm.pieces.length > 0)?_c('BasicPaginator',{ref:"paginator",staticClass:"mt-2",attrs:{"callback":_vm.handleChangePagination,"total-rows":_vm.pieces.length}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }