<template>
  <div>
    <validation-observer ref="pieceForm">
      <b-form @submit="$event.preventDefault()">
        <b-row>

          <!-- #region::Piece import number input -->
          <b-col md="3">
            <div class="d-flex">
              <TextInputWithValidation
                v-model="importNumber"
                vid="importNumber"
                rules="required"
                class="flex-fill"
                type="text"
                label="No. Importación"
                name="no. importación"
                maxlength="250"
                placeholder="No. Importación"
                :label-class="['red-dot']"
                :disabled="isLoadingDataByImportNumber"
                @blur="onBlurImportNumber"
              />
              <b-spinner
                v-if="isLoadingDataByImportNumber"
                class="ml-1 mt-50 align-self-center"
                small
              />
            </div>
          </b-col>
          <!-- #endregion::Piece import number input -->

          <!-- #region::Arrival date select -->
          <b-col
            md="3"
            class="mt-50"
          >
            <DatePickerWithValidation
              v-model="arriveDate"
              vid="arriveDate"
              rules="required"
              label="Fecha de llegada"
              name="fecha de llegada"
              placeholder="Fecha de llegada"
              :show-required-dot="true"
            />
          </b-col>
          <!-- #endregion::Arrival date select -->

          <!-- #region::Piece availability select -->
          <b-col md="3">
            <SelectWithValidation
              v-model="selectedAvailability"
              vid="availability"
              rules="required"
              name="disponibilidad"
              label="Disponibilidad"
              property="text"
              placeholder="Disponibilidad"
              :label-class="['red-dot']"
              :options="getAvailabilities"
              :disabled="preSale"
            />
          </b-col>
          <!-- #endregion::Piece availability select -->

          <!-- #region::Piece invoice number input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="invoiceNumber"
              vid="invoiceNumber"
              rules="required"
              type="text"
              label="No. Factura"
              name="no. factura"
              maxlength="250"
              placeholder="No. Factura"
              :label-class="['red-dot']"
            />
          </b-col>
          <!-- #endregion::Piece invoice number input -->

          <!-- #region::Piece petition number input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="petitionNumber"
              vid="petitionNumber"
              rules="required"
              type="text"
              label="No. Pedimento"
              name="no. pedimento"
              maxlength="250"
              placeholder="No. Pedimento"
              :label-class="['red-dot']"
            />
          </b-col>
          <!-- #endregion::Piece petition number input -->

          <!-- #region::Piece tag input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="tag"
              vid="tag"
              rules="required"
              type="text"
              label="Tag"
              name="tag"
              maxlength="250"
              placeholder="Tag"
              :label-class="['red-dot']"
            />
          </b-col>
          <!-- #endregion::Piece tag input -->

          <!-- #region::Piece serie number input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="serieNumber"
              vid="serieNumber"
              rules="required"
              type="text"
              label="No. Serie"
              name="no. serie"
              maxlength="250"
              placeholder="No. Serie"
              :label-class="['red-dot']"
            />
          </b-col>
          <!-- #endregion::Piece serie number input -->

          <!-- #region::Piece condition select -->
          <b-col md="3">
            <SelectWithValidation
              v-model="selectedCondition"
              vid="condition"
              :disabled="disableSelectCondition()"
              rules="required"
              name="condición"
              label="Condición"
              property="text"
              placeholder="Condición"
              :label-class="['red-dot']"
              :options="disableSelectCondition() ? getConditions : getConditions.slice(1)"
            />
          </b-col>
          <!-- #endregion::Piece condition select -->

          <!-- #region::Piece location select -->
          <b-col md="3">
            <SelectWithValidation
              v-model="selectedLocation"
              vid="location"
              rules="required"
              name="ubicación"
              label="Ubicación"
              property="text"
              placeholder="Ubicación"
              :label-class="['red-dot']"
              :options="getLocations"
              :disabled="id ? true : false"
            />
          </b-col>
          <!-- #endregion::Piece location select -->

          <!-- #region begin::USD invoice unit price input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="unitPriceInvoiceUsd"
              vid="unitPriceInvoiceUsd"
              rules="decimal"
              type="text"
              label="Precio unitario factura USD"
              name="precio unitario factura USD"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
            />
          </b-col>
          <!-- #endregion end::USD invoice unit price input -->

          <!-- #region begin::Exchange rate input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="exchangeRate"
              vid="exchangeRate"
              rules="decimal"
              type="text"
              label="Tipo de cambio"
              name="tipo de cambio"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
            />
          </b-col>
          <!-- #endregion end::Exchange rate input -->

          <!-- #region begin::M/N invoice unit price input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="unitPriceInvoiceMn"
              vid="unitPriceInvoiceMn"
              rules="decimal"
              type="text"
              label="Precio unitario factura M/N"
              name="precio unitario factura MD/N"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
            />
          </b-col>
          <!-- #endregion end::M/N invoice unit price input -->

          <!-- #region begin::M/N utility / pay for MN'SC input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="costUtility"
              vid="costUtility"
              rules="decimal"
              type="text"
              label="Costeo MN'SC / Utilidad(5%) M/N"
              name="costeo MN'SC / Utilidad(5%) M/N"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
            />
          </b-col>
          <!-- #endregion end::M/N utility / pay for MN'SC input -->

          <!-- #region begin::USD supplier price input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="providersPriceUsd"
              vid="providersPriceUsd"
              rules="decimal"
              type="text"
              label="Precio proveedor USD"
              name="precio proveedor USD"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
            />
          </b-col>
          <!-- #endregion end::USD supplier price input -->

          <!-- #region begin::M/N supplier pay input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="providersCostingMn"
              vid="providersCostingMn"
              rules="decimal"
              type="text"
              label="Costeo proveedor M/N"
              name="costeo proveedor M/N"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
            />
          </b-col>
          <!-- #endregion end::M/N supplier pay input -->

          <!-- #region::Form actions button -->
          <b-col
            md="3"
            align-self="center"
          >
            <b-button
              variant="principal-btn"
              class="mt-1 w-100 principal-btn"
              @click="editionMode ? updatePiece() : onAddPiece()"
            >
              {{ editionMode ? 'Guardar Cambios' : 'Agregar' }}
            </b-button>
          </b-col>
          <!-- #endregion::Form actions button -->

        </b-row>

      </b-form>
    </validation-observer>
  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import { required, alphaNum } from '@validations'
import { ValidationObserver, extend } from 'vee-validate'
import {
  BRow, BCol, BButton, VBTooltip, BForm, BSpinner,
} from 'bootstrap-vue'

import { v4 as uuidv4 } from 'uuid'

import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import DatePickerWithValidation from '@/components/forms/DatePickerWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    ValidationObserver,
    SelectWithValidation,
    TextInputWithValidation,
    DatePickerWithValidation,
    // eslint-disable-next-line vue/no-unused-components
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      id: '',
      tag: '',
      arriveDate: '',
      serieNumber: '',
      importNumber: '',
      invoiceNumber: '',
      petitionNumber: '',
      selectedLocation: '',
      selectedCondition: '',
      selectedAvailability: '',

      unitPriceInvoiceUsd: '',
      exchangeRate: '',
      unitPriceInvoiceMn: '',
      costUtility: '',
      providersPriceUsd: '',
      providersCostingMn: '',

      // * 14/09/2022 - TAG: Validations
      alphaNum,
      required,

      type: '',
      isLoadingDataByImportNumber: false,
    }
  },
  computed: {
    ...mapGetters({
      // #region Store getters to fill selectables form components
      getLocations: 'pieces/getLocations',
      getConditions: 'pieces/getConditions',
      getAvailabilities: 'pieces/getAvailabilities',
      // #endregion
      getSelectedType: 'products/getSelectedType',
      getSelectedDate: 'products/getSelectedDate',
      getPreSale: 'products/getPreSale',
      getEditablePiece: 'pieces/getEditablePiece',

      getLocalPieces: 'pieces/getLocalPieces',
      getExternalPieces: 'pieces/getExternalPieces',

      getEditionMode: 'pieces/getEditionMode',
    }),
    selectedType: {
      get() { return this.getSelectedType },
    },
    selectedDate: {
      get() { return this.getSelectedDate },
    },
    preSale: {
      get() { return this.getPreSale },
    },
    editablePiece: {
      get() { return this.getEditablePiece },
      set(value) { this.setEditablePiece(value) },
    },
    editionMode: {
      get() { return this.getEditionMode },
      set(value) { this.setEditionMode(value) },
    },
    localPieces: {
      get() { return this.getLocalPieces },
    },
    externalPieces: {
      get() { return this.getExternalPieces },
    },
    location() {
      return this.getLocations.find(element => element.text === this.piece.Location)
    },
    condition() {
      return this.getConditions.find(element => element.text === this.piece.Condition)
    },
    availability() {
      return this.getAvailabilities.find(element => element.text === this.piece.Availability)
    },
  },
  watch: {
    selectedType(newValue, oldValue) {
      if ((newValue.text === 'Usado' && oldValue.text === 'Remanufacturado')
          || (newValue.text === 'Remanufacturado' && oldValue.text === 'Usado')) {
        return
      }

      if (newValue.text === 'Nuevo') {
        this.selectedCondition = {
          text: 'Nuevo',
        }
      } else {
        this.selectedCondition = {
          text: 'Habilitado',
        }
      }
    },
    selectedDate(newValue) {
      this.arriveDate = newValue
    },
    preSale(newValue) {
      this.selectedAvailability = newValue ? { text: 'Preventa' } : { text: 'Disponible' }
    },
    editablePiece(pieceToEdit) {
      if (pieceToEdit) {
        this.id = pieceToEdit.type === 'local' ? pieceToEdit.id : pieceToEdit.IdPiece
        this.tag = pieceToEdit.Tag
        this.arriveDate = pieceToEdit.ArriveDate
        this.serieNumber = pieceToEdit.NoSeries
        this.importNumber = pieceToEdit.NoImport
        this.invoiceNumber = pieceToEdit.NoInvoice
        this.petitionNumber = pieceToEdit.NoPetition
        this.selectedLocation = { text: pieceToEdit.Location }
        this.selectedCondition = { text: pieceToEdit.Condition }
        this.selectedAvailability = { text: pieceToEdit.Availability }
        if (pieceToEdit.type === 'local') {
          this.exchangeRate = pieceToEdit.ExchangeRate
          this.costUtility = pieceToEdit.CostUtility
        } else {
          this.exchangeRate = pieceToEdit.Tc
          this.costUtility = pieceToEdit.Ccost
        }
        this.unitPriceInvoiceUsd = pieceToEdit.UnitPriceInvoiceUsd
        this.unitPriceInvoiceMn = pieceToEdit.UnitPriceInvoiceMn
        this.providersPriceUsd = pieceToEdit.ProvidersPriceUsd
        this.providersCostingMn = pieceToEdit.ProvidersCostingMn

        this.editionMode = true
        this.type = pieceToEdit.type
      }
    },
    editionMode(editionMode) {
      if (!editionMode) {
        // this.cleanFieldsForm()
        this.editablePiece = null
        this.$refs.pieceForm.reset()
      }
    },
  },
  created() {
    extend('alpha_num', {
      ...alphaNum,
      message: 'El campo solo debe contender letras y números',
    })
  },
  mounted() {
    if (this.disableSelectCondition()) {
      this.selectedCondition = {
        text: 'Nuevo',
      }
    } else {
      this.selectedCondition = {
        text: 'Habilitado',
      }
    }

    this.selectedAvailability = this.preSale ? { text: 'Preventa' } : { text: 'Disponible' }
  },
  methods: {
    ...mapActions({
      addLocalPiece: 'pieces/addLocalPiece',
      setEditionMode: 'pieces/setEditionMode',
      setEditablePiece: 'pieces/setEditablePiece',
      updateLocalPiece: 'pieces/updateLocalPiece',
      addPieceToUpdate: 'pieces/addPieceToUpdate',
      checkImportNumbers: 'pieces/checkImportNumbers',
    }),
    disableSelectCondition() {
      if (this.selectedType.text === 'Nuevo') {
        return true
      }

      return false
    },
    async onAddPiece() {
      const success = await this.$refs.pieceForm.validate()

      if (success) {
        this.addLocalPiece({
          id: uuidv4(),
          Tag: this.tag,
          ArriveDate: this.arriveDate,
          NoSeries: this.serieNumber,
          NoImport: this.importNumber,
          NoInvoice: this.invoiceNumber,
          NoPetition: this.petitionNumber,
          Location: this.selectedLocation.text,
          Condition: this.selectedCondition.text,
          Availability: this.selectedAvailability.text,

          UnitPriceInvoiceUsd: this.unitPriceInvoiceUsd,
          ExchangeRate: this.exchangeRate,
          UnitPriceInvoiceMn: this.unitPriceInvoiceMn,
          CostUtility: this.costUtility,
          ProvidersPriceUsd: this.providersPriceUsd,
          ProvidersCostingMn: this.providersCostingMn,
        })

        this.showToast(
          'Pieza agregada',
          'La pieza nueva se ha agregado correctamente.',
          'success',
        )

        // this.cleanFieldsForm()
        // this.$refs.pieceForm.reset()
      } else {
        this.showToast(
          'Error de validación',
          'Se encontraron uno o más errores en la validación del formulario de la pieza.',
          'danger',
        )
      }
    },

    /**
    * Actualización de pieza
    *
    * @summary Actualiza las piezas de las tablas de piezas locales y externas
    */
    updatePiece() {
      if (this.type === 'local') {
        this.updatePieceInList(this.localPieces.find(p => p.id === this.id))
      } else if (this.type === 'external') {
        const pieceToUpdate = this.externalPieces.find(p => p.IdPiece === this.id)
        this.addPieceToUpdate(pieceToUpdate)
        this.updatePieceInList(pieceToUpdate)
      }

      this.showToast(
        'Pieza actualizada',
        'La pieza se ha actualizado correctamente.',
        'success',
      )

      // this.cleanFieldsForm()
      this.editionMode = false
      this.editablePiece = null
      this.$refs.pieceForm.reset()
    },

    /**
    * Reestablece el formulario
    *
    * @summary Limpia los campos del formulario
    */
    cleanFieldsForm() {
      this.id = ''
      this.tag = ''
      this.arriveDate = ''
      this.serieNumber = ''
      this.importNumber = ''
      this.invoiceNumber = ''
      this.petitionNumber = ''
      this.selectedLocation = ''
      this.selectedCondition = this.disableSelectCondition() ? { text: 'Nuevo' } : { text: 'Habilitado' }
      this.selectedAvailability = this.preSale ? { text: 'Preventa' } : { text: 'Disponible' }

      this.unitPriceInvoiceMn = ''
      this.unitPriceInvoiceUsd = ''
      this.exchangeRate = ''
      this.costUtility = ''
      this.providersPriceUsd = ''
      this.providersCostingMn = ''

      this.editionMode = false
      this.type = ''
    },

    /**
    * Actualiza la información de las piezas
    *
    * @summary Actualiza la información de las pieza en las listas del estado
    * @param {object} piece - Pieza que se edita
    */
    updatePieceInList(piece) {
      this.$set(piece, 'Tag', this.tag)
      this.$set(piece, 'ArriveDate', this.arriveDate)
      this.$set(piece, 'NoSeries', this.serieNumber)
      this.$set(piece, 'NoImport', this.importNumber)
      this.$set(piece, 'NoInvoice', this.invoiceNumber)
      this.$set(piece, 'NoPetition', this.petitionNumber)
      this.$set(piece, 'Location', this.selectedLocation.text)
      this.$set(piece, 'Condition', this.selectedCondition.text)
      this.$set(piece, 'Availability', this.selectedAvailability.text)

      this.$set(piece, 'UnitPriceInvoiceUsd', this.unitPriceInvoiceUsd)
      if (piece.type === 'local') {
        this.$set(piece, 'ExchangeRate', this.exchangeRate)
        this.$set(piece, 'CostUtility', this.costUtility)
      } else {
        this.$set(piece, 'Tc', this.exchangeRate)
        this.$set(piece, 'Ccost', this.costUtility)
      }
      this.$set(piece, 'ExchangeRate', this.exchangeRate)
      this.$set(piece, 'CostUtility', this.costUtility)
      this.$set(piece, 'UnitPriceInvoiceMn', this.unitPriceInvoiceMn)
      this.$set(piece, 'ProvidersPriceUsd', this.providersPriceUsd)
      this.$set(piece, 'ProvidersCostingMn', this.providersCostingMn)
    },

    /**
    * Verifica el número de importación
    *
    * @summary Verifica el número de importación y obtiene la información de la pieza
    * y la muestra en los campos correspondientes
    */
    async onBlurImportNumber() {
      try {
        this.isLoadingDataByImportNumber = true
        const response = await this.checkImportNumbers(this.importNumber)
        const pieceData = response.data.data.data[0]

        if (pieceData) {
          this.arriveDate = pieceData.ArriveDate
          this.petitionNumber = pieceData.NoPetition
          this.invoiceNumber = pieceData.NoInvoice
          this.selectedAvailability = { text: pieceData.Availability }
        }
      } catch (error) {
        this.showToast(
          'Error de validación',
          'Se encontraron uno o más errores al validar el número de importación.',
          'danger',
        )
      } finally {
        this.isLoadingDataByImportNumber = false
      }
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
