<template>
  <b-card class="border border-dark shadow-none">
    <b-card-text>
      <b-container>
        <b-row
          class="mb-2"
        >
          <b-col
            md="4"
            class="mb-1"
          >
            <h4>Piezas agregadas</h4>
          </b-col>

          <!-- #region begin::Searcher -->
          <b-col md="12">
            <BasicSearcher
              :callback="handleSearch"
              placeholder="Buscar piezas"
            />
          </b-col>
          <!-- #endregion end::Searcher -->

          <template v-if="pieces.length > 0">

            <b-col md="12">
              <b-table
                :items="pieces"
                :fields="columns"
                responsive
                class="mt-2"
              >

                <!-- #region:Virtual column for index -->
                <template #cell(No)="data">
                  {{ indexFrom + data.index }}
                </template>
                <!-- #endregion:Virtual column for index -->

                <!-- #region:Virtual column for tag -->
                <template #cell(Tag)="{ item }">
                  <span class="text-nowrap">{{ item.Tag }}</span>
                </template>
                <!-- #endregion:Virtual column for tag -->

                <!-- #region:Virtual column for NoSeries -->
                <template #cell(NoSeries)="{ item }">
                  <span class="text-nowrap">{{ item.NoSeries }}</span>
                </template>
                <!-- #endregion:Virtual column for NoSeries -->

                <!-- #region:Virtual column for NoPetition -->
                <template #cell(NoPetition)="{ item }">
                  <span class="text-nowrap">{{ item.NoPetition }}</span>
                </template>
                <!-- #endregion:Virtual column for NoPetition -->

                <!-- #region:Virtual column for NoInvoice -->
                <template #cell(NoInvoice)="{ item }">
                  <span class="text-nowrap">{{ item.NoInvoice }}</span>
                </template>
                <!-- #endregion:Virtual column for NoInvoice -->

                <!-- #region:Virtual column for NoImport -->
                <template #cell(NoImport)="{ item }">
                  <span class="text-nowrap">{{ item.NoImport }}</span>
                </template>
                <!-- #endregion:Virtual column for NoImport -->

                <!-- #region:Virtual column for Condition -->
                <template #cell(Condition)="{ item }">
                  <span class="text-nowrap">{{ item.Condition }}</span>
                </template>
                <!-- #endregion:Virtual column for Condition -->

                <!-- #region:Virtual column for Availability -->
                <template #cell(Availability)="{ item }">
                  <span class="text-nowrap">
                    <h6><b-badge
                      class="ava-label"
                      :variant="getAvailabilityStyle(item.Availability)"
                    >{{ item.Availability }}
                    </b-badge>
                    </h6>
                  </span>
                </template>
                <!-- #endregion:Virtual column for Availability -->

                <!-- #region:Virtual column for Location -->
                <template #cell(Location)="{ item }">
                  <span class="text-nowrap">{{ item.Location }}</span>
                </template>
                <!-- #endregion:Virtual column for Location -->

                <!-- #region:Virtual column for ArriveDate -->
                <template #cell(ArriveDate)="{ item }">
                  <span class="text-nowrap">{{ item.ArriveDate | moment("DD/MM/YYYY") }}</span>
                </template>
                <!-- #endregion:Virtual column for ArriveDate -->

                <!-- #region:Virtual column for UnitPriceInvoiceUsd -->
                <template #cell(UnitPriceInvoiceUsd)="{ item }">
                  <span class="text-nowrap">{{ item.UnitPriceInvoiceUsd | currencyWhite }}</span>
                </template>
                <!-- #endregion:Virtual column for UnitPriceInvoiceUsd -->

                <!-- #region:Virtual column for ExchangeRate -->
                <template #cell(ExchangeRate)="{ item }">
                  <span class="text-nowrap">{{ item.Tc | currencyWhite }}</span>
                </template>
                <!-- #endregion:Virtual column for ExchangeRate -->

                <!-- #region:Virtual column for UnitPriceInvoiceMn -->
                <template #cell(UnitPriceInvoiceMn)="{ item }">
                  <span class="text-nowrap">{{ item.UnitPriceInvoiceMn | currencyWhite }}</span>
                </template>
                <!-- #endregion:Virtual column for UnitPriceInvoiceMn -->

                <!-- #region:Virtual column for CostUtility -->
                <template #cell(CostUtility)="{ item }">
                  <span class="text-nowrap">{{ item.Ccost | currencyWhite }}</span>
                </template>
                <!-- #endregion:Virtual column for CostUtility -->

                <!-- #region:Virtual column for ProvidersPriceUsd -->
                <template #cell(ProvidersPriceUsd)="{ item }">
                  <span class="text-nowrap">{{ item.ProvidersPriceUsd | currencyWhite }}</span>
                </template>
                <!-- #endregion:Virtual column for ProvidersPriceUsd -->

                <!-- #region:Virtual column for ProvidersCostingMn -->
                <template #cell(ProvidersCostingMn)="{ item }">
                  <span class="text-nowrap">{{ item.ProvidersCostingMn | currencyWhite }}</span>
                </template>
                <!-- #endregion:Virtual column for ProvidersCostingMn -->

                <!-- #region::A virtual column for actions -->
                <template #cell(actions)="{ item }">

                  <div class="d-flex">
                    <!-- #region::Update piece button -->
                    <b-button
                      v-b-tooltip.hover.top="'Editar'"
                      variant="flat-secondary"
                      class="btn-icon rounded-circle"
                      :disabled="item.delete === true"
                      @click="onUpdatePiece(item)"
                    >
                      <feather-icon icon="Edit3Icon" />
                    </b-button>
                    <!-- #endregion::Update piece button -->

                    <!-- #region::Delete piece button -->
                    <b-button
                      v-b-tooltip.hover.top="'Eliminar'"
                      variant="flat-secondary"
                      class="btn-icon rounded-circle"
                      :disabled="item.delete === true"
                      @click="onDeletePiece(item)"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                    <!-- #endregion::Delete piece button -->
                  </div>

                </template>
              <!-- #endregion::A virtual column for actions -->

              </b-table>
            </b-col>
          </template>

          <template v-if="pieces.length === 0 && loadingPieces === false">
            <b-col
              md="12"
              class="mt-2"
            >
              <b-alert
                variant="warning"
                show
              >
                <div class="alert-body">
                  <span>No se encontraron piezas.</span>
                </div>
              </b-alert>
            </b-col>
          </template>

        </b-row>
      </b-container>
    </b-card-text>

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="pieces.length > 0"
      class="mt-2"
      :callback="handleChangePagination"
      :total-rows="totalPieces"
    />
    <!-- #endregion begin::Pagination & items per list -->

  </b-card>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BCol, BRow, BCard, BCardText, BContainer, BTable, BBadge, VBTooltip, BButton, BAlert,
} from 'bootstrap-vue'

import getError from '@/helpers/ErrorsHandler'
import BasicSearcher from '@/components/forms/BasicSearcher.vue'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BAlert,
    BBadge,
    BTable,
    BButton,
    BCardText,
    BContainer,
    BasicSearcher,
    BasicPaginator,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: 10,
      searchTerm: '',
      currentPage: 1,
      loadingPieces: false,
      indexFrom: 0,
      columns: [
        {
          key: 'No',
          label: 'No.',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'Tag',
          label: 'Tag',
          thClass: this.$ability.can('read', 'Piece_tag') ? 'bg-light' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_tag') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'NoSeries',
          label: 'No. serie',
          thClass: this.$ability.can('read', 'Piece_serie') ? 'bg-light text-nowrap' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_serie') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'NoPetition',
          label: 'No. Pedimento',
          thClass: this.$ability.can('read', 'Piece_petition') ? 'bg-light text-nowrap' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_petition') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'NoInvoice',
          label: 'No. factura',
          thClass: this.$ability.can('read', 'Piece_invoice') ? 'bg-light text-nowrap' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_invoice') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'NoImport',
          label: 'No. importación',
          thClass: this.$ability.can('read', 'Piece_import') ? 'bg-light text-nowrap' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_import') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Condition',
          label: 'Condición',
          thClass: this.$ability.can('read', 'Piece_condition') ? 'bg-light' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_condition') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Availability',
          label: 'Disponibilidad',
          thClass: this.$ability.can('read', 'Piece_availability') ? 'bg-light' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_availability') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Location',
          label: 'Ubicación',
          thClass: this.$ability.can('read', 'Piece_location') ? 'bg-light' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_location') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'ArriveDate',
          label: 'Fecha de llegada',
          thClass: this.$ability.can('read', 'Piece_arrival_date') ? 'bg-light text-nowrap' : 'd-none',
          tdClass: this.$ability.can('read', 'Piece_arrival_date') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'UnitPriceInvoiceUsd',
          label: 'Precio unitario factura USD',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'ExchangeRate',
          label: 'Tipo de cambio',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'UnitPriceInvoiceMn',
          label: 'Precio unitario factura M/N',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'CostUtility',
          label: 'Costeo MN\'SC / Utilidad(5%) M/N',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'ProvidersPriceUsd',
          label: 'Precio proveedores USD',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'ProvidersCostingMn',
          label: 'Costeo proveedores M/N',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'actions',
          label: 'Acciones',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getPreSale: 'products/getPreSale',
      getIdProduct: 'products/getIdProduct',
      getGlobalDate: 'pieces/getGlobalDate',
      getEditionMode: 'pieces/getEditionMode',
      getEditablePiece: 'pieces/getEditablePiece',
      getSelectedType: 'products/getSelectedType',
      getExternalPieces: 'pieces/getExternalPieces',
      getPiecesToDelete: 'pieces/getPiecesToDelete',
      getPiecesToUpdate: 'pieces/getPiecesToUpdate',
      getAvailablePieces: 'pieces/getAvailablePieces',
      getTotalExternalPieces: 'pieces/getTotalExternalPieces',
    }),
    /**
     * Indentificador del producto
     */
    idProduct: {
      get() { return this.getIdProduct },
      set(value) { this.setIdProduct(value) },
    },
    /**
     * Piezas que se cagan en la lista
     */
    pieces: {
      get() { return this.getExternalPieces },
      set(value) { this.setExternalPieces(value) },
    },
    /**
     * Total de piezas del producto en el servidor
     */
    totalPieces: {
      get() { return this.getTotalExternalPieces },
      set(value) { this.setTotalExternalPieces(value) },
    },
    /**
     * Piezas que seran elminadas
     */
    piecesToDelete: {
      get() { return this.getPiecesToDelete },
    },
    /**
     * Piezas que seran actualizadas
     */
    piecesToUpdate: {
      get() { return this.getPiecesToUpdate },
    },
    /**
     * Indica si todas las piezas tienen estado preventa
     */
    preSale: {
      get() { return this.getPreSale },
    },
    /**
     * Indica la pieza que se carga en el formulario de pieza
     * y que puede ser editada
     */
    editablePiece: {
      get() { return this.getEditablePiece },
      set(value) { this.setEditablePiece(value) },
    },
    /**
    * Tipo del producto seleccionado
    */
    selectedType: {
      get() { return this.getSelectedType },
    },
    /**
     * Se almacena para actulizar la información del producto
     */
    globalDate: {
      get() { return this.getGlobalDate },
    },
    /**
     * Indica si alguna pieza se está editando
     */
    editionMode: {
      get() { return this.getEditionMode },
      set(value) { this.setEditionMode(value) },
    },
    /**
     * Indica si todas las piezas tienen estado de disponibilidad
     * en Disponible
     */
    availablePieces: {
      get() { return this.getAvailablePieces },
    },
  },
  watch: {
    /**
     * Escucha el cambio de piezas en la lista y aplica el efecto de eliminación a las
     * nuevas piezas tomando en cuenta las que se han agregado al arreglo de piezas a
     * eliminar
     */
    pieces() {
      this.pieces.map(piece => {
        if (this.piecesToDelete.map(p => p.IdPiece).includes(piece.IdPiece)) {
          this.$set(piece, '_rowVariant', 'danger')
          this.$set(piece, 'delete', true)
        }

        // Si la pieza fue actualizada, al recargar los elementos de la lista se aplican los cambios
        if (this.piecesToUpdate.map(p => p.IdPiece).includes(piece.IdPiece)) {
          const pieceToUpdate = this.piecesToUpdate.find(p => p.IdPiece === piece.IdPiece)
          this.$set(piece, 'Tag', pieceToUpdate.Tag)
          this.$set(piece, 'ArriveDate', pieceToUpdate.ArriveDate)
          this.$set(piece, 'NoSeries', pieceToUpdate.NoSeries)
          this.$set(piece, 'NoImport', pieceToUpdate.NoImport)
          this.$set(piece, 'NoInvoice', pieceToUpdate.NoInvoice)
          this.$set(piece, 'NoPetition', pieceToUpdate.NoPetition)
          this.$set(piece, 'Location', pieceToUpdate.Location)
          this.$set(piece, 'Condition', pieceToUpdate.Condition)
          this.$set(piece, 'Availability', pieceToUpdate.Availability)
        } else {
          if (this.preSale) {
            this.$set(piece, 'Availability', 'Preventa')
          }

          if (this.globalDate) {
            this.$set(piece, 'ArriveDate', this.globalDate)
          }

          if (this.availablePieces) {
            this.$set(piece, 'Availability', 'Disponible')
          }
        }

        return piece
      })
    },
    preSale(newPreSale, oldPreSale) {
      if (newPreSale) {
        this.pieces.map(piece => {
          this.$set(piece, 'Availability', 'Preventa')
          return piece
        })
        this.piecesToUpdate.map(piece => {
          this.$set(piece, 'Availability', 'Preventa')
          return piece
        })
      }

      if (oldPreSale && !newPreSale) {
        this.pieces.map(piece => {
          this.$set(piece, 'Availability', 'Disponible')
          return piece
        })
        this.piecesToUpdate.map(piece => {
          this.$set(piece, 'Availability', 'Disponible')
          return piece
        })
      }
    },
    selectedType(type) {
      this.pieces.map(piece => {
        this.$set(piece, 'Condition', type.text === 'Nuevo' ? 'Nuevo' : 'Habilitado')
        return piece
      })
    },
    globalDate(date) {
      this.pieces.map(piece => {
        this.$set(piece, 'ArriveDate', date)
        return piece
      })
    },
  },
  async mounted() {
    this.loadProductPiecesList()
  },
  methods: {
    ...mapActions({
      setEditionMode: 'pieces/setEditionMode',
      addPieceToDelete: 'pieces/addPieceToDelete',
      setEditablePiece: 'pieces/setEditablePiece',
      loadPiecesProduct: 'pieces/loadPiecesProduct',
      setExternalPieces: 'pieces/setExternalPieces',
      deletePieceToUpdate: 'pieces/deletePieceToUpdate',
      setTotalExternalPieces: 'pieces/setTotalExternalPieces',
    }),
    handleSearch(searchTerm) {
      this.currentPage = 1
      this.searchTerm = searchTerm
      this.loadProductPiecesList()
    },
    getAvailabilityStyle(availability) {
      switch (availability) {
        case 'Disponible':
          return 'light-success'
        case 'No disponible':
          return 'light-danger'
        default:
          return 'light-primary'
      }
    },

    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.editionMode = false
      this.currentPage = currentPage
      this.perPage = perPage
      this.loadProductPiecesList()
    },

    /**
    * Carga de registros de tipo piezas
    *
    * @summary ⁡⁢⁣⁡⁢⁢⁡⁣⁣⁡⁣⁢⁣⁡⁣⁢⁢⁡⁢⁢⁢‍Carga y establece las piezas correspondientes a un producto
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de piezas
    */
    async loadProductPiecesList() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.loadingPieces = true

        const response = await this.loadPiecesProduct(this.loadParams())
        this.pieces = response.data.data.pieces.data
        this.totalPieces = response.data.data.pieces.total
        this.indexFrom = response.data.data.pieces.from

        this.$swal.close()
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.loadingPieces = false
      }
    },
    onUpdatePiece(piece) {
      this.$set(piece, 'type', 'external')
      window.scrollTo(0, 0)
      this.editablePiece = piece
    },
    onDeletePiece(piece) {
      if (piece.Transfer === 'Si') {
        this.showToast(
          'Error de validación',
          'La pieza no puede ser eliminada debido a que se encuentra en una orden de traspaso',
          'warning',
        )
        return
      }

      this.$swal({
        title: '¿Deseas continuar?',
        text: '¡Al eliminar la pieza, se afectará el inventario del producto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Sí, eliminar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.addPieceToDelete(piece)

          if (this.piecesToUpdate.map(p => p.IdPiece).includes(piece.IdPiece)) {
            this.deletePieceToUpdate(piece)
          }

          this.$set(piece, '_rowVariant', 'danger')
          this.$set(piece, 'delete', true)
        }
      })
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista
    */
    loadParams() {
      return {
        perPage: this.perPage,
        idProduct: this.idProduct,
        searchTerm: this.searchTerm,
        currentPage: this.currentPage,
      }
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .feather-48{
    width: 48px;
    height: 48px;
  }
</style>
