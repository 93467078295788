import axios from '@/libs/axios'

const uploadImage = async (formData, action) => {
  if (!formData) {
    return
  }

  try {
    const url = `${process.env.VUE_APP_API_URL}/images/${action}`
    const response = await axios.post(url, formData)

    console.log(response)
  } catch (error) {
    console.error('Error al cargar la imagen, revisar logs')
    console.log(error)
  }
}

export default uploadImage
