<template>
  <div>
    <b-card class="border border-dark shadow-none mt-0">
      <b-card-text>
        <b-container>

          <!-- #region::Import pieces from .csv file button -->
          <b-row
            class="mb-2"
          >
            <b-col md="4">
              <h4>Información de las piezas</h4>
            </b-col>
            <b-col
              md="8"
              class="float-right"
            >
              <b-row>
                <b-col class="col-md-auto col ml-auto mt-auto">
                  <b-form-checkbox
                    v-model="preSale"
                  >
                    <strong>Preventa</strong>
                  </b-form-checkbox>
                </b-col>
                <b-col
                  v-if="preSale"
                  class="col-md-auto col"
                >
                  <DatePickerWithValidation
                    v-model="selectedDate"
                    vid="selectedDate"
                    label="Fecha de llegada"
                    name="fecha de llegada"
                    placeholder="Selecciona la fecha de llegada"
                  />
                </b-col>
                <b-col class="col-md-auto col mt-auto">
                  <b-button
                    variant="principal-btn"
                    class="my-1 my-md-0 principal-btn"
                    @click="$refs.importingPiecesModal.showModal()"
                  >
                    Importar piezas
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- #endregion::Import pieces from .csv file button -->

          <!-- #region::Row loop -->
          <SavePieceForm />
          <!-- #endregion::Row loop -->

          <ImportPiecesModal
            ref="importingPiecesModal"
            :add-pieces-from-file="addPiecesFromFile"
          />
        </b-container>
      </b-card-text>
    </b-card>

    <LocalPiecesList />
    <ExternalPiecesList v-if="idProduct" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BRow, BCol, BCard, BCardText, BContainer, BButton, BFormCheckbox,
} from 'bootstrap-vue'

import { v4 as uuidv4 } from 'uuid'

import SavePieceForm from '@/modules/production/products/components/pieces/SavePieceForm.vue'
import LocalPiecesList from '@/modules/production/products/components/form/LocalPiecesList.vue'
import ExternalPiecesList from '@/modules/production/products/components/form/ExternalPiecesList.vue'

import DatePickerWithValidation from '@/components/forms/DatePickerWithValidation.vue'
import ImportPiecesModal from '@/modules/production/products/components/modals/ImportPiecesModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BContainer,
    BFormCheckbox,
    SavePieceForm,
    LocalPiecesList,
    ImportPiecesModal,
    ExternalPiecesList,
    DatePickerWithValidation,
  },
  computed: {
    ...mapGetters({
      getPieces: 'pieces/getPieces',
      getPreSale: 'products/getPreSale',
      getGlobalDate: 'pieces/getGlobalDate',
      getIdProduct: 'products/getIdProduct',
      getSelectedDate: 'products/getSelectedDate',
      getAvailablePieces: 'pieces/getAvailablePieces',
    }),
    /**
     * Indica si todas las piezas tienen estado preventa
     */
    preSale: {
      get() { return this.getPreSale },
      set(value) { this.setPreSale(value) },
    },
    /**
     * Indentificador del producto
     */
    idProduct: {
      get() { return this.getIdProduct },
    },
    /**
     * Se aplica a todas las piezas cuando se activa el estado preventa
     */
    selectedDate: {
      get() { return this.getSelectedDate },
      set(value) { this.setSelectedDate(value) },
    },
    /**
     * Se almacena para actulizar la información del producto
     */
    globalDate: {
      get() { return this.getGlobalDate },
      set(value) { this.setGlobalDate(value) },
    },
    /**
     * Indica si todas las piezas tienen estado preventa
     */
    pieces: {
      get() { return this.getPieces },
      set(value) { this.setPieces(value) },
    },
    /**
     * Indica si todas las piezas tienen estado de disponibilidad
     * en Disponible
     */
    availablePieces: {
      get() { return this.getAvailablePieces },
      set(value) { this.setAvailablePieces(value) },
    },
  },
  watch: {
    preSale(newPreSale, oldPreSale) {
      if (!newPreSale) {
        this.selectedDate = ''
      }

      // Si se cambia el estado de preSale (verdadero) a un estado negativo
      // se debe aplicar el estado disponible a todas las piezas
      if (oldPreSale && !newPreSale) {
        this.availablePieces = true
      } else {
        this.availablePieces = false
      }
    },
    selectedDate(newSelectedDate) {
      if (newSelectedDate) {
        this.globalDate = newSelectedDate
      }
    },
  },
  methods: {
    ...mapActions({
      setPreSale: 'products/setPreSale',
      addLocalPiece: 'pieces/addLocalPiece',
      setGlobalDate: 'pieces/setGlobalDate',
      setSelectedDate: 'products/setSelectedDate',
      setAvailablePieces: 'pieces/setAvailablePieces',
    }),
    addPiecesFromFile(data) {
      data.map(async piece => {
        this.changeRealCount(this.realCount + 1, piece)
      })

      // wait for the DOM to finish rendering
      this.$nextTick(() => {
        this.$swal.close()
      })
    },
    changeRealCount(count = null, data = null) {
      if (data) {
        this.realCount = count

        this.addLocalPiece({
          id: uuidv4(),
          Tag: data.Tag,
          ArriveDate: (this.preSale && this.selectedDate) ? this.selectedDate : data['Fecha de llegada'],
          NoSeries: data['No.Serie'],
          NoImport: data['No.Importacion'],
          NoInvoice: data['No.Factura'],
          NoPetition: data['No.Pedimento'],
          Location: data.Ubicacion,
          Condition: data.Condicion,
          Availability: this.preSale ? 'Preventa' : data.Disponibilidad,
          UnitPriceInvoiceUsd: data['Precio unitario factura USD'],
          ExchangeRate: data['Tipo de Cambio'],
          UnitPriceInvoiceMn: data['Precio unitario factura M/N'],
          CostUtility: data['Costeo MN\'SC / Utilidad(5%) M/N'],
          ProvidersPriceUsd: data['Precio proveedor USD'],
          ProvidersCostingMn: data['Costeo proveedor M/N'],
        })
      } else {
        this.addLocalPiece({
          id: uuidv4(),
          Availability: this.preSale ? 'Preventa' : 'Disponible',
        })
      }
    },
    deletePiece(index) {
      const deletedPiece = this.pieces.at(index)

      if (deletedPiece.Transfer === 'Si') {
        this.showToast(
          'Error de validación',
          'La pieza no puede ser eliminada debido a que se encuentra en una orden de traspaso',
          'warning',
        )
        return
      }

      this.$swal({
        title: '¿Deseas continuar?',
        text: '¡Al eliminar la pieza, se afectará el inventario del producto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Sí, eliminar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (this.id) {
            this.deletedPieces.push(deletedPiece)
          }

          this.$swal({
            title: 'Cargando...',
            allowOutsideClick: false,
          })
          this.$swal.showLoading()

          setTimeout(() => {
            this.pieces.splice(index, 1)
            this.realCount -= 1

            this.$nextTick(() => {
              this.$swal.close()
            })
          }, 10)
        }
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
