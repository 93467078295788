<template>
  <div>
    <b-modal
      id="import-pieces-modal"
      ref="import-pieces-modal"
      centered
      size="lg"
      title="Importar piezas"
      @hidden="onCloseModal"
    >

      <!-- #region::Modal content -->
      <b-card-text>
        <b-row>

          <!-- #region::Modal title -->
          <b-col md="12">
            Sube tus piezas mediante un archivo CSV.
          </b-col>
          <!-- #endregion::Modal title -->

          <!-- #region::Download template button -->
          <b-col
            md="12"
            class="my-2"
          >
            <small>¿No cuentas con plantilla? º </small>
            <b-button
              variant="flat-success"
              class="csv-btn"
              @click="onDownloadCSVFile"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span class="align-middle">Descargar plantilla</span>
            </b-button>
          </b-col>
          <!-- #endregion::Download template button -->

          <b-col
            v-if="showError"
            md="12"
            class="mb-2"
          >
            <b-alert
              variant="danger"
              :show="true"
              class="mb-0"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                {{ error }}
              </div>
            </b-alert>
          </b-col>

          <!-- #region::Dropzone -->
          <b-col
            v-if="!parsed"
            md="12"
          >
            <b-img
              fluid
              :src="active
                ? require('@/assets/images/placeholders/csv-dropzone-drop.svg')
                : require('@/assets/images/placeholders/csv-dropzone.svg')"
              @dragenter.prevent="toggleActive"
              @dragleave.prevent="toggleActive"
              @dragover.prevent
              @drop.prevent="onDropFile"
              @click="onSelectFile"
            />
          </b-col>
          <!-- #endregion::Dropzone -->

          <!-- #region::File name and delete option -->
          <b-col v-if="parsed">
            <b-card>
              <b-card-text>
                <b-row>
                  <b-col
                    class="align-self-center"
                    md="6"
                  >
                    <span><feather-icon
                      class="mr-50"
                      icon="FileTextIcon"
                    />{{ file.name }}</span>
                  </b-col>

                  <b-col
                    cols="12"
                    md="auto"
                    class="ml-auto"
                  >
                    <b-button
                      variant="flat-secondary"
                      @click="onDeleteLoadedFile"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        class="mr-50"
                      />
                      <span class="align-middle">Eliminar</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </b-col>

          <!-- #endregion::File name and delete option -->

        </b-row>
      </b-card-text>
      <!-- #endregion::Modal content -->

      <!-- #region::Footer -->
      <template #modal-footer>
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="hideModal"
        >
          Cancelar
        </b-button>
        <b-button
          variant="principal-btn"
          class="principal-btn"
          @click="onImportFile"
        >
          <span class="align-middle">Importar archivo</span>
        </b-button>
      </template>
    <!-- #endregion::Footer -->

    </b-modal>

    <input
      v-show="false"
      id="fileSelector"
      ref="fileSelector"
      type="file"
      accept=".csv"
      @change="onSelectedFile($event)"
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BRow, BCol, VBModal, BCardText, BButton, BImg, BCard, BAlert,
} from 'bootstrap-vue'
import Papa from 'papaparse'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BAlert,
    BButton,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    addPiecesFromFile: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      active: false,
      error: null,

      file: '',
      content: [],
      parsed: false,
    }
  },
  computed: {
    ...mapGetters({
      getSelectedType: 'products/getSelectedType',
    }),
    selectedType: {
      get() { return this.getSelectedType },
    },
    newProduct: {
      get() { return this.selectedType.text === 'Nuevo' },
    },
    emptyTypeProduct: {
      get() { return this.selectedType === '' },
    },
    showError() {
      return this.error
    },
  },
  methods: {
    ...mapActions({
      downloadCSVFile: 'pieces/downloadCSVFile',
    }),
    onDropFile(event) {
      const file = event.dataTransfer.files[0]
      if (this.validFormatFile(file)) {
        this.file = file
        this.parseFile()
      }
    },
    onSelectFile() {
      this.$refs.fileSelector.click()
    },
    onSelectedFile(event) {
      const file = event.target.files[0]
      if (this.validFormatFile(file)) {
        this.file = file
        this.parseFile()
      }
    },
    toggleActive() {
      this.active = !this.active
    },
    parseFile() {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        // eslint-disable-next-line func-names
        complete: function (results) {
          this.content = results

          if (this.canLoadPieces()) {
            this.parsed = true
            this.error = null
            // this.addPiecesFromFile(this.content.data)
            // this.hideModal()
          }
        }.bind(this),
      })
    },
    canLoadPieces() {
      if (!this.validCSVFileContentCondition()) {
        this.error = 'La condición de una o más piezas con corresponde con el tipo de producto seleccionado.'
        this.onDeleteLoadedFile(false)
        return false
      }

      if (!this.validCSVFileContentDateFormat()) {
        this.error = 'Una o más fechas no corresponden al formato de fechas válidas.'
        this.onDeleteLoadedFile(false)
        return false
      }

      if (!this.validateNumberWithTwoDecimals(this.content.data.map(data => data['Precio unitario factura USD']))) {
        this.error = 'Uno o más valores de la columna "precio unitario factura USD" es incorrecto.'
        this.onDeleteLoadedFile(false)
        return false
      }

      if (!this.validateNumberWithTwoDecimals(this.content.data.map(data => data['Tipo de Cambio']))) {
        this.error = 'Uno o más valores de la columna "tipo de cambio" es incorrecto.'
        this.onDeleteLoadedFile(false)
        return false
      }

      if (!this.validateNumberWithTwoDecimals(this.content.data.map(data => data['Precio unitario factura M/N']))) {
        this.error = 'Uno o más valores de la columna "precio unitario factura M/N" es incorrecto.'
        this.onDeleteLoadedFile(false)
        return false
      }

      if (!this.validateNumberWithTwoDecimals(this.content.data.map(data => data['Costeo MN\'SC / Utilidad(5%) M/N']))) {
        this.error = 'Uno o más valores de la columna "costeo MN\'SC / utilidad(5%) M/N" es incorrecto.'
        this.onDeleteLoadedFile(false)
        return false
      }

      if (!this.validateNumberWithTwoDecimals(this.content.data.map(data => data['Precio proveedor USD']))) {
        this.error = 'Uno o más valores de la columna "precio proveedor USD" es incorrecto.'
        this.onDeleteLoadedFile(false)
        return false
      }

      if (!this.validateNumberWithTwoDecimals(this.content.data.map(data => data['Costeo proveedor M/N']))) {
        this.error = 'Uno o más valores de la columna "costeo proveedor M/N" es incorrecto.'
        this.onDeleteLoadedFile(false)
        return false
      }

      if (!this.validCSVFileHeaders()) {
        this.error = 'Uno o más encabezados no corresponden al formato establecido en la plantilla.'
        this.onDeleteLoadedFile(false)
        return false
      }

      if (!this.validRowsCount()) {
        this.error = 'El archivo no debe contener más de 500 piezas.'
        this.onDeleteLoadedFile(false)
        return false
      }

      return true
    },

    /**
     * Validate all the conditions from pieces based on product condition if required
     */
    validCSVFileContentCondition() {
      let validCondition = false

      if (this.emptyTypeProduct) {
        validCondition = true
      } else {
        let isValidCondition = null

        if (this.newProduct) {
          isValidCondition = data => data.Condicion === 'Nuevo'
        } else {
          isValidCondition = data => data.Condicion === 'Habilitado'
                                  || data.Condicion === 'Funcional'
                                  || data.Condicion === 'Desarmado'
                                  || data.Condicion === 'Incompleto'
        }

        validCondition = this.content.data.every(isValidCondition)
      }

      return validCondition
    },

    /**
     * Validate if all dates from pieces have correct format
     */
    validCSVFileContentDateFormat() {
      const isYYYYMMDD = str => {
        /* eslint-disable no-useless-escape */
        let [yyyy, mm, dd] = str.split(/[\.\-\/]/)
        yyyy = +yyyy
        mm = +mm
        dd = +dd
        const mm0 = mm - 1
        const date = new Date(yyyy, mm0, dd, 0, 0, 0, 0)
        return mm0 === date.getMonth() && dd === date.getDate() && yyyy === date.getFullYear()
      }

      const isValidDate = data => isYYYYMMDD(data['Fecha de llegada'])
      return this.content.data.every(isValidDate)
    },

    /**
     * Validate if all values from pieces have two decimals
     */
    validateNumberWithTwoDecimals(values) {
      const regex = /^\d{1,10}(\.\d{1,2})?$/;

      return values.every(value => regex.test(value));
    },

    /**
     * Validate if headers from file have the correct description
     */
    validCSVFileHeaders() {
      if (this.content.meta.fields[0] !== 'Tag') {
        return false
      }

      if (this.content.meta.fields[1] !== 'No.Serie') {
        return false
      }

      if (this.content.meta.fields[2] !== 'No.Pedimento') {
        return false
      }

      if (this.content.meta.fields[3] !== 'No.Factura') {
        return false
      }

      if (this.content.meta.fields[4] !== 'No.Importacion') {
        return false
      }

      if (this.content.meta.fields[5] !== 'Condicion') {
        return false
      }

      if (this.content.meta.fields[6] !== 'Disponibilidad') {
        return false
      }

      if (this.content.meta.fields[7] !== 'Ubicacion') {
        return false
      }

      if (this.content.meta.fields[8] !== 'Fecha de llegada') {
        return false
      }

      if (this.content.meta.fields[9] !== 'Precio unitario factura USD') {
        return false
      }

      if (this.content.meta.fields[10] !== 'Tipo de Cambio') {
        return false
      }

      if (this.content.meta.fields[11] !== 'Precio unitario factura M/N') {
        return false
      }

      if (this.content.meta.fields[12] !== 'Costeo MN\'SC / Utilidad(5%) M/N') {
        return false
      }

      if (this.content.meta.fields[13] !== 'Precio proveedor USD') {
        return false
      }

      if (this.content.meta.fields[14] !== 'Costeo proveedor M/N') {
        return false
      }

      return true
    },

    /**
     * Validate if file has more than 500 rows (pieces)
     */
    validRowsCount() {
      if (this.content.data.length > 500) {
        return false
      }

      return true
    },


    /**
     * Validate the file format (must be .csv)
     */
    validFormatFile(file) {
      if (!file.type || file.type !== 'text/csv') {
        this.error = 'El formato del archivo es incorrecto. Formato permitido para cargar: .csv'
        this.onDeleteLoadedFile(false)
        return false
      }

      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2)

      if (sizeInMB > 2) {
        this.error = 'El tamaño del archivo es demasiado grande. Tamaño permitido menor o igual a 2MB.'
        this.onDeleteLoadedFile(false)
        return false
      }

      return true
    },

    /**
     * Show modal
     */
    showModal() {
      this.$refs['import-pieces-modal'].show()
    },

    /**
     * Hide modal
     */
    hideModal() {
      this.$refs['import-pieces-modal'].hide()
    },

    /**
     * Delete loaded file
     * @summary Delete the file loaded and reset the form
     */
    onDeleteLoadedFile(hideError = true) {
      this.file = null

      if (hideError) {
        this.error = null
      }

      this.active = false
      this.parsed = false
      document.getElementById('fileSelector').value = ''
    },

    /**
     * Import file
     * @summary Import the file loaded and add the pieces to the table
     */
    onImportFile() {
      if (this.parsed) {
        this.hideModal()
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        setTimeout(() => {
          this.addPiecesFromFile(this.content.data)
        }, 10)
      } else {
        this.showToast(
          'Archivo no cargado',
          'Es necesario agregar un archivo para poder continuar.',
          'warning',
        )
      }
    },

    /**
     * Close modal
     * @summary Close the modal and delete the file loaded
     */
    onCloseModal() {
      this.onDeleteLoadedFile()
      this.$emit('on-close-modal')
    },

    /**
     * Download CSV file
     * @summary Download the CSV file template
     */
    onDownloadCSVFile() {
      try {
        window.location.href = `${process.env.VUE_APP_API_URL}/storage/Plantilla_Carga_Masiva_de_Piezas.xlsx`
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
     * Show toast
     * @summary Show a toast message
     * @param {String} title Title of the toast
     * @param {String} text Text of the toast
     * @param {String} variant Variant of the toast
     */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
