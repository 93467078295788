<template>
  <b-card>
    <b-card-text>
      <b-container>
        <b-row>

          <!-- #region::Card title -->
          <b-col md="12">
            <h4>Información del producto</h4>
          </b-col>
          <!-- #endregion::Card title -->

          <!-- #region begin::Images inputs group -->
          <b-col md="6">
            <b-form-group
              label="Imágenes del producto (3 máx., .png, .jpeg, .jpg)"
              label-size="sm"
              class="mt-1 mb-0"
            >
              <div
                class="d-flex justify-content-center my-2"
              >
                <b-row>
                  <div
                    v-show="!pendingImage"
                    class="row col"
                  >
                    <template v-if="!loadingDataForm">
                      <b-col md="4">
                        <ImagesLoader
                          ref="img01"
                          :image="image01"
                          @image-loaded="image01Loaded"
                          @image-deleted="image01Deleted"
                        />
                      </b-col>
                      <b-col md="4">
                        <ImagesLoader
                          ref="img02"
                          :image="image02"
                          @image-loaded="image02Loaded"
                          @image-deleted="image02Deleted"
                        />
                      </b-col>
                      <b-col md="4">
                        <ImagesLoader
                          ref="img03"
                          :image="image03"
                          @image-loaded="image03Loaded"
                          @image-deleted="image03Deleted"
                        />
                      </b-col>
                    </template>
                    <template v-else>
                      <b-col md="12">
                        <b-skeleton-img
                          animation="wave"
                        />
                      </b-col>
                    </template>
                  </div>
                  <div
                    v-show="pendingImage"
                    class="row col"
                  >
                    <b-col md="4">
                      <img
                        :src="pendingImageUrl"
                        alt="Pendiente de cargar"
                        class="img-fluid"
                        style="height: auto;aspect-ratio: 1/1;"
                      >
                    </b-col></div>
                  <b-col
                    cols="12"
                    class="mt-1"
                  >
                    <small class="text-primary">Arrastra o selecciona tus imágenes</small>
                  </b-col>
                </b-row>
              </div>
              <b-form-checkbox
                v-model="pendingImage"
                class="custom-control-primary"
              >
                Imagen pendiente
              </b-form-checkbox>
            </b-form-group>
            <b-alert
              variant="danger"
              :show="!imagesLoaded && submitted"
              class="mb-2"
            >
              <div class="alert-body">
                <small>Es necesario agregar al menos una imagen</small>
              </div>
            </b-alert>
          </b-col>
          <!-- #endregion end::Images inputs group -->

          <!-- #region begin::Internal code input -->
          <b-col md="6">
            <TextInputWithValidation
              v-model="internalCode"
              vid="internalCode"
              rules="required"
              type="text"
              label="Código interno del producto"
              name="código interno"
              maxlength="250"
              placeholder="Escribe el código interno del producto"
            />
          </b-col>
          <!-- #endregion end::Internal code input -->

          <!-- #region begin::Name input -->
          <b-col md="6">
            <vue-autosuggest
              v-model="name"
              :suggestions="filteredOptions"
              :limit="5"
              :input-props="{
                id:'autosuggest__input',
                class:'form-control',
                name:'name',
                placeholder:'Escribe el nombre del producto'
              }"
              class="mt-1"
              @input="onInputNameChange"
              @selected="onSelectedName"
            >
              <template slot="before-input">
                <small>Nombre del producto</small>
              </template>
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
              </template>
            </vue-autosuggest>
            <b-alert
              variant="danger"
              :show="name === '' && submitted"
              class="mt-1 mb-2"
            >
              <div class="alert-body">
                <small>Es campo nombre es obligatorio</small>
              </div>
            </b-alert>
          </b-col>
          <!-- #endregion end::Name input -->

          <!-- #region begin::Brand select -->
          <b-col md="6">
            <SelectWithValidation
              v-model="selectedBrand"
              vid="brand"
              rules="required"
              name="marca"
              label="Marca del producto"
              property="Name"
              placeholder="Selecciona la marca del producto"
              :options="getBrands"
            />
            <!-- #region begin::Create new brand button -->
            <b-button
              class="mb-1"
              variant="flat-primary"
              size="sm"
              @click="$refs.saveModalBrand.showModal('Nueva marca')"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Nueva marca</span>
            </b-button>
            <!-- #endregion end::Create new brand button -->

          </b-col>
          <!-- #endregion end::Brand select -->

          <!-- #region begin::Model input -->
          <b-col md="6">
            <TextInputWithValidation
              v-model="model"
              vid="model"
              rules="required"
              type="text"
              label="Modelo del producto"
              name="modelo"
              maxlength="200"
              placeholder="Escribe el modelo del producto"
            />
          </b-col>
          <!-- #endregion end::Model input -->

          <!-- #region begin::Category select -->
          <b-col md="3">
            <SelectWithValidation
              v-model="selectedCategory"
              vid="category"
              rules="required"
              name="subcategoría"
              label="Subcategoría del producto"
              property="Name"
              placeholder="Selecciona la subcategoría"
              :options="getCategories"
            />
            <!-- #region begin::Create new category button -->
            <b-button
              class="mb-1"
              variant="flat-primary"
              size="sm"
              @click="$refs.saveModalCategory.showModal('Nueva subcategoría')"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Nueva subcategoría</span>
            </b-button>
            <!-- #endregion end::Create new category button -->

          </b-col>
          <!-- #endregion end::Category select -->

          <!-- #region begin::Procategory static field -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="procategoryName"
              vid="procategory"
              type="text"
              label="Categoría del producto"
              name="categoría"
              placeholder="Categoría del producto"
              :disabled="true"
            />
          </b-col>
          <!-- #endregion begin::Procategory static field -->

          <!-- #region begin::Type select -->
          <b-col md="6">
            <b-row>
              <b-col
                :md="isNewProduct ? 7 : 12"
              >
                <SelectWithValidation
                  v-model="selectedType"
                  vid="type"
                  rules="required"
                  name="tipo"
                  label="Tipo del producto"
                  property="text"
                  placeholder="Selecciona la tipo del producto"
                  :clearable="false"
                  :options="getTypes"
                  @input="onTypeChange"
                />
              </b-col>
              <b-col
                v-if="isNewProduct"
                md="5"
              >
                <TextInputWithValidation
                  v-model="item"
                  vid="item"
                  rules="required"
                  type="text"
                  label="Item"
                  name="item"
                  maxlength="250"
                  placeholder="Item"
                />
              </b-col>
            </b-row>
          </b-col>
          <!-- #endregion end::Type select -->

          <!-- #region begin::Supplier select -->
          <b-col
            v-if="isNewProduct"
            md="6"
          >
            <SelectWithValidation
              v-model="selectedSupplier"
              vid="supplier"
              name="proveedor"
              label="Proveedor del producto"
              property="Name"
              placeholder="Selecciona el proveedor del producto"
              :options="getSuppliers"
            />
            <!-- #region begin::Create new supplier button -->
            <b-button
              class="mb-1"
              variant="flat-primary"
              size="sm"
              @click="$refs.saveModalSupplier.showModal('Nuevo proveedor')"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Nuevo proveedor</span>
            </b-button>
            <!-- #endregion end::Create new supplier button -->

          </b-col>
          <!-- #endregion end::Supplier select -->

          <!-- #region begin::Tapestry color select -->
          <b-col :md="isNewProduct ? 3 : 6">
            <SelectWithValidation
              v-model="selectedTapestry"
              vid="tapestry"
              rules="required"
              name="color de tapiceria"
              label="Color de tapicería"
              property="Name"
              placeholder="Selecciona el color de tapicería"
              :options="getTapestryColors"
            />
          </b-col>
          <!-- #endregion end::Tapestry color select -->

          <!-- #region begin::Paint color select -->
          <b-col :md="isNewProduct ? 3 : 6">
            <SelectWithValidation
              v-model="selectedPaint"
              vid="paint"
              rules="required"
              name="color de pintura"
              label="Color de pintura"
              property="Name"
              placeholder="Selecciona el color de pintura"
              :options="getPaintColors"
            />
          </b-col>
          <!-- #endregion end::Paint color select -->

          <!-- #region begin::SAT code input -->
          <b-col md="6">
            <TextInputWithValidation
              v-model="satCode"
              vid="satCode"
              type="text"
              label="Clave SAT"
              name="clave SAT"
              maxlength="250"
              placeholder="Escribe la clave SAT del producto"
            />
          </b-col>
          <!-- #endregion end::SAT code input -->

          <!-- #region begin::Destination input -->
          <b-col md="6">
            <TextInputWithValidation
              v-model="destination"
              vid="destination"
              rules="required"
              type="text"
              label="Destino del producto"
              name="destino"
              maxlength="250"
              placeholder="Escribe el destino del producto"
            />
          </b-col>
          <!-- #endregion end::Destination input -->

          <!-- #region begin::Product low count spin button -->
          <b-col
            md="6"
            class="mt-1"
          >
            <label
              for="lowCountSpinner"
              class="mb-0"
            >Cantidad de bajo inventario</label>
            <b-form-spinbutton
              id="lowCountSpinner"
              v-model="lowCount"
              min="0"
              max="1000"
              @change="changeLowCount($event)"
            />
            <b-alert
              variant="danger"
              :show="lowCount > piecesCount"
              class="my-1"
            >
              <div class="alert-body">
                <small> La cantidad de bajo inventario no debe ser mayor a la cantidad del producto</small>
              </div>
            </b-alert>
            <b-alert
              show
              class="mt-1"
              variant="primary"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="AlertCircleIcon"
                />
                <small
                  class="ml-25"
                >Se notificará por medio de correo electrónico.</small>
              </div>
            </b-alert>
          </b-col>
          <!-- #endregion end::Product low count spin button -->

        </b-row>
      </b-container>
    </b-card-text>

    <!-- #region begin::Modal for creating brand -->
    <SaveModal
      ref="saveModalBrand"
      :label-name="'Marca'"
      placeholder="Escribe el nombre de la marca"
      @model-saved="handleBrandSaved"
    />
    <!-- #endregion end::Modal for creating brand -->

    <!-- #region begin::Modal for creating category -->
    <SaveModal
      ref="saveModalCategory"
      :label-name="'Subcategoria'"
      placeholder="Escribe el nombre de la subcategoría"
      @model-saved="handleCategorySaved"
      @reset-data="selectedProcategory = null"
    >
      <template v-slot:model-selector>
        <SelectWithValidation
          v-model="selectedProcategory"
          vid="procategory"
          rules="required"
          name="categoría"
          label="Categoría"
          property="Name"
          placeholder="Selecciona su categoría"
          :options="getProcategories"
          :label-class="['red-dot']"
        />
      </template>
    </SaveModal>
    <!-- #endregion end::Modal for creating category -->

    <!-- #region begin::Modal for creating supplier -->
    <SaveModal
      ref="saveModalSupplier"
      :label-name="'Nombre'"
      placeholder="Escribe el nombre del proveedor"
      @model-saved="handleSupplierSaved"
    />
    <!-- #endregion end::Modal for creating supplier -->
  </b-card>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BSkeletonImg, BCard, BCardText, BContainer, BRow, BCol,
  BFormGroup, BAlert, BButton, BFormSpinbutton, BFormCheckbox,
} from 'bootstrap-vue'

import { VueAutosuggest } from 'vue-autosuggest'

import SaveModal from '@/components/modals/SaveModal.vue'
import ImagesLoader from '@/components/forms/ImagesLoader.vue'
import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BButton,
    SaveModal,
    BCardText,
    BFormGroup,
    BContainer,
    ImagesLoader,
    BSkeletonImg,
    BFormCheckbox,
    VueAutosuggest,
    BFormSpinbutton,
    SelectWithValidation,
    TextInputWithValidation,
  },
  data() {
    return {
      /**
       * Selectedd procategory from saving modal
       */
      selectedProcategory: null,
      pendingImageUrl: `${process.env.VUE_APP_API_URL}/imagenes/pending/small.jpeg`,
      // pendingImageUrl: 'https://apioptimum.demosturn.com/imagenes/pending/middle.jpeg',
    }
  },
  computed: {
    ...mapGetters({
      // Catalogues
      getTypes: 'products/getTypes',
      getBrands: 'brands/getBrands',
      getSuggests: 'products/getSuggests',
      getSuppliers: 'suppliers/getSuppliers',
      getCategories: 'categories/getCategories',
      getPaintColors: 'products/getPaintColors',
      getSelectedType: 'products/getSelectedType',
      getTapestryColors: 'products/getTapestryColors',

      // Product info
      getName: 'products/getName',
      getItem: 'products/getItem',
      getModel: 'products/getModel',
      getImages: 'products/getImages',
      getImage01: 'products/getImage01',
      getImage02: 'products/getImage02',
      getImage03: 'products/getImage03',
      getSatCode: 'products/getSatCode',
      getLowCount: 'products/getLowCount',
      getBackItem: 'products/getBackItem',
      getSubmitted: 'products/getSubmitted',
      getDestination: 'products/getDestination',
      getInternalCode: 'products/getInternalCode',
      getPendingImage: 'products/getPendingImage',
      getImagesLoaded: 'products/getImagesLoaded',
      getSelectedBrand: 'products/getSelectedBrand',
      getSelectedPaint: 'products/getSelectedPaint',
      getFilteredOptions: 'products/getFilteredOptions',
      getLoadingDataForm: 'products/getLoadingDataForm',
      getSelectedCategory: 'products/getSelectedCategory',
      getSelectedTapestry: 'products/getSelectedTapestry',
      getSelectedSupplier: 'products/getSelectedSupplier',
      getCanceledTypeChange: 'products/getCanceledTypeChange',
      getBackSelectedSupplier: 'products/getBackSelectedSupplier',

      // * 19/05/2023 - TAG: Información de componentes externos
      getMinNormalPrice: 'products/getMinNormalPrice',
      getMaxNormalPrice: 'products/getMaxNormalPrice',
      // Respaldo para los precios normales de lista
      getBackMinNormalPrice: 'products/getBackMinNormalPrice',
      getBackMaxNormalPrice: 'products/getBackMaxNormalPrice',
      // Banderas para el setting de precios
      getPriceToBeDecided: 'products/getPriceToBeDecided',
      // Precios de mayorista
      getMinWholesalerPrice: 'products/getMinWholesalerPrice',
      getMaxWholesalerPrice: 'products/getMaxWholesalerPrice',
      // Respaldo de los precios de mayorista
      getBackMinWholesalerPrice: 'products/getBackMinWholesalerPrice',
      getBackMaxWholesalerPrice: 'products/getBackMaxWholesalerPrice',
      // Precios de lista de productos usados
      getLfPrice: 'products/getLfPrice',
      getDrPrice: 'products/getDrPrice',
      getSuggestMaxPrice: 'products/getSuggestMaxPrice',
      // Respaldo de los precios de productos usados
      getBackLfPrice: 'products/getBackLfPrice',
      getBackDrPrice: 'products/getBackDrPrice',
      getBackSuggestMaxPrice: 'products/getBackSuggestMaxPrice',

      // * 22/05/2023 - TAG: Listas de piezas

      getPiecesCount: 'pieces/getPiecesCount',
      getLocalPieces: 'pieces/getLocalPieces',

      getProcategories: 'procategories/getProcategories',
    }),
    /**
     * Nombre del producto
     */
    name: {
      get() { return this.getName },
      set(value) { this.setName(value) },
    },
    /**
     * Item que se establece cuando el producto es de tipo nuevo
     */
    item: {
      get() { return this.getItem },
      set(value) { this.setItem(value) },
    },
    /**
     * Modelo del producto
     */
    model: {
      get() { return this.getModel },
      set(value) { this.setModel(value) },
    },
    /**
     * Imagenes del producto
     */
    images: {
      get() { return this.getImages },
      set(value) { this.setImages(value) },
    },
    /**
     * Imagenes del producto
     */
    image01: {
      get() { return this.getImage01 },
      set(value) { this.setImage01(value) },
    },
    /**
     * Imagenes del producto
     */
    image02: {
      get() { return this.getImage02 },
      set(value) { this.setImage02(value) },
    },
    /**
     * Imagenes del producto
     */
    image03: {
      get() { return this.getImage03 },
      set(value) { this.setImage03(value) },
    },
    /**
     * Código SAT del producto
     */
    satCode: {
      get() { return this.getSatCode },
      set(value) { this.setSatCode(value) },
    },
    /**
     * Cantidad mínima para bajo inventario del producto
     */
    lowCount: {
      get() { return this.getLowCount },
      set(value) { this.setLowCount(value) },
    },
    /**
     * Respaldo para el item del producto que es utilizado al
     * cambiar el tipo de producto y cancelar el cambio
     */
    backItem: {
      get() { return this.getBackItem },
      set(value) { this.setBackItem(value) },
    },
    /**
     * Verifica el submit del formulario
     */
    submitted: {
      get() { return this.getSubmitted },
      set(value) { this.setSubmitted(value) },
    },
    /**
     * Destino del producto
     */
    destination: {
      get() { return this.getDestination },
      set(value) { this.setDestination(value) },
    },
    /**
     * Código interno del producto
     */
    internalCode: {
      get() { return this.getInternalCode },
      set(value) { this.setInternalCode(value) },
    },
    /**
     * Indica si hay una imagen pendiente
     */
    pendingImage: {
      get() { return this.getPendingImage },
      set(value) { this.setPendingImage(value) },
    },
    /**
     * Indica si se han cargado imágenes
     */
    imagesLoaded: { get() { return this.getImagesLoaded } },
    /**
    * Tipo del producto seleccionado
    */
    selectedType: {
      get() { return this.getSelectedType },
      set(value) { this.setSelectedType(value) },
    },
    /**
     * Marca de producto seleccionada
     */
    selectedBrand: {
      get() { return this.getSelectedBrand },
      set(value) { this.setSelectedBrand(value) },
    },
    /**
     * Color de producto seleccionado
     */
    selectedPaint: {
      get() { return this.getSelectedPaint },
      set(value) { this.setSelectedPaint(value) },
    },
    /**
     * Opciones para el campo de sugerencias del nombre
     * del producto
     */
    filteredOptions: {
      get() { return this.getFilteredOptions },
      set(value) { this.setFilteredOptions(value) },
    },
    /**
     * Indica si alguna información del producto está haciendo
     * atendida con el servidor. Actualizacion o carga de información
     */
    loadingDataForm: {
      get() { return this.getLoadingDataForm },
      set(value) { this.setLoadingDataForm(value) },
    },
    /**
     * Categoría del producto seleccionada
     */
    selectedCategory: {
      get() { return this.getSelectedCategory },
      set(value) { this.setSelectedCategory(value) },
    },
    /**
     * Color de tapicería del producto seleccionada
     */
    selectedTapestry: {
      get() { return this.getSelectedTapestry },
      set(value) { this.setSelectedTapestry(value) },
    },
    /**
     * Proveedor del producto seleccionado
     */
    selectedSupplier: {
      get() { return this.getSelectedSupplier },
      set(value) { this.setSelectedSupplier(value) },
    },
    /**
     * Indica el tipo de producto previo que fue cambiado por otro. Es
     * necesario para mantener el control del tipo en caso de que el cambio
     * se cancelado y volver al anterior
     */
    canceledTypeChange: {
      get() { return this.getCanceledTypeChange },
      set(value) { this.setCanceledTypeChange(value) },
    },
    /**
     * Respaldo para el proveedor seleccionado
     */
    backSelectedSupplier: {
      get() { return this.getBackSelectedSupplier },
      set(value) { this.setBackSelectedSupplier(value) },
    },

    // * 19/05/2023 - TAG: Información de componentes externos
    minNormalPrice: {
      get() { return this.getMinNormalPrice },
      set(value) { this.setMinNormalPrice(value) },
    },
    maxNormalPrice: {
      get() { return this.getMaxNormalPrice },
      set(value) { this.setMaxNormalPrice(value) },
    },
    // Respaldo para los precios normales de lista
    backMinNormalPrice: {
      get() { return this.getBackMinNormalPrice },
      set(value) { this.setBackMinNormalPrice(value) },
    },
    backMaxNormalPrice: {
      get() { return this.getBackMaxNormalPrice },
      set(value) { this.setBackMaxNormalPrice(value) },
    },
    // Banderas para el setting de precios
    priceToBeDecided: {
      get() { return this.getPriceToBeDecided },
      set(value) { this.setPriceToBeDecided(value) },
    },
    // Precios de mayorista
    minWholesalerPrice: {
      get() { return this.getMinWholesalerPrice },
      set(value) { this.setMinWholesalerPrice(value) },
    },
    maxWholesalerPrice: {
      get() { return this.getMaxWholesalerPrice },
      set(value) { this.setMaxWholesalerPrice(value) },
    },
    // Respaldo de los precios de mayorista
    backMinWholesalerPrice: {
      get() { return this.getBackMinWholesalerPrice },
      set(value) { this.setBackMinWholesalerPrice(value) },
    },
    backMaxWholesalerPrice: {
      get() { return this.getBackMaxWholesalerPrice },
      set(value) { this.setBackMaxWholesalerPrice(value) },
    },
    // Precios de lista para productos usados
    lfPrice: {
      get() { return this.getLfPrice },
      set(value) { this.setLfPrice(value) },
    },
    drPrice: {
      get() { return this.getDrPrice },
      set(value) { this.setDrPrice(value) },
    },
    suggestMaxPrice: {
      get() { return this.getSuggestMaxPrice },
      set(value) { this.setSuggestMaxPrice(value) },
    },
    // Respaldo de los precios de productos usados
    backLfPrice: {
      get() { return this.getBackLfPrice },
      set(value) { this.setBackLfPrice(value) },
    },
    backDrPrice: {
      get() { return this.getBackDrPrice },
      set(value) { this.setBackDrPrice(value) },
    },
    backSuggestMaxPrice: {
      get() { return this.getBackSuggestMaxPrice },
      set(value) { this.setBackSuggestMaxPrice(value) },
    },

    // * 22/05/2023 - TAG: Listas de piezas

    piecesCount: {
      get() { return this.getPiecesCount },
    },

    localPieces: {
      get() { return this.getLocalPieces },
    },

    /**
     * Establece el nombre de la categoría del producto a partir de la
     * subcategoría seleccionada
     */
    procategoryName: {
      get() { return this.selectedCategory?.CategoryName },
      set() {},
    },

    /**
     * Indica si el producto es de tipo "Nuevo"
     */
    isNewProduct() {
      return this.selectedType.text === 'Nuevo'
    },
  },
  watch: {
    item(newValue) {
      if (this.isNewProduct) {
        this.backItem = newValue
      }
    },
    selectedSupplier(newValue) {
      if (this.isNewProduct) {
        this.backSelectedSupplier = newValue
      }
    },
  },
  methods: {
    ...mapActions({
      // Catalogues
      createBrand: 'brands/createBrand',
      createSupplier: 'suppliers/createSupplier',
      createCategory: 'categories/createCategory',
      loadBrandsWithoutPagination: 'brands/loadBrandsWithoutPagination',
      loadSuppliersWithoutPagination: 'suppliers/loadSuppliersWithoutPagination',
      loadCategoriesWithoutPagination: 'categories/loadCategoriesWithoutPagination',

      // Product info
      setName: 'products/setName',
      setItem: 'products/setItem',
      setModel: 'products/setModel',
      setImages: 'products/setImages',
      setImage01: 'products/setImage01',
      setImage02: 'products/setImage02',
      setImage03: 'products/setImage03',
      setSatCode: 'products/setSatCode',
      setLowCount: 'products/setLowCount',
      setBackItem: 'products/setBackItem',
      setSubmitted: 'products/setSubmitted',
      setDestination: 'products/setDestination',
      setInternalCode: 'products/setInternalCode',
      setPendingImage: 'products/setPendingImage',
      setSelectedType: 'products/setSelectedType',
      setSelectedBrand: 'products/setSelectedBrand',
      setSelectedPaint: 'products/setSelectedPaint',
      setFilteredOptions: 'products/setFilteredOptions',
      setLoadingDataForm: 'products/setLoadingDataForm',
      setSelectedCategory: 'products/setSelectedCategory',
      setSelectedTapestry: 'products/setSelectedTapestry',
      setSelectedSupplier: 'products/setSelectedSupplier',
      setCanceledTypeChange: 'products/setCanceledTypeChange',
      setBackSelectedSupplier: 'products/setBackSelectedSupplier',

      // * 19/05/2023 - TAG: Acciones para componentes externos
      setMinNormalPrice: 'products/setMinNormalPrice',
      setMaxNormalPrice: 'products/setMaxNormalPrice',
      // Respaldo para los precios normales de lista
      setBackMinNormalPrice: 'products/setBackMinNormalPrice',
      setBackMaxNormalPrice: 'products/setBackMaxNormalPrice',
      // Banderas para el setting de precios
      setPriceToBeDecided: 'products/setPriceToBeDecided',
      // Precios de mayorista
      setMinWholesalerPrice: 'products/setMinWholesalerPrice',
      setMaxWholesalerPrice: 'products/setMaxWholesalerPrice',
      // Respaldo de los precios de mayorista
      setBackMinWholesalerPrice: 'products/setBackMinWholesalerPrice',
      setBackMaxWholesalerPrice: 'products/setBackMaxWholesalerPrice',
      // Precios de lista de productos usados
      setLfPrice: 'products/setLfPrice',
      setDrPrice: 'products/setDrPrice',
      setSuggestMaxPrice: 'products/setSuggestMaxPrice',
      // Respaldo de los precios de productos usados
      setBackLfPrice: 'products/setBackLfPrice',
      setBackDrPrice: 'products/setBackDrPrice',
      setBackSuggestMaxPrice: 'products/setBackSuggestMaxPrice',
      // Limpia los campos de precios de lista de todos los componentes
      clearPrices: 'products/clearPrices',
    }),

    /**
    * Evento del nombre del producto
    *
    * @summary Detecta los cambios al ingresar el nombre para cargar las sugerencias
    * @param {string} text - Texto ingresado en el campo del nombre
    */
    onInputNameChange(text) {
      this.name = text

      if (text === '' || text === undefined) {
        return
      }
      const filteredData = this.getSuggests
        .filter(item => item.Name.toLowerCase()
          .indexOf(text.toLowerCase()) > -1)
        .slice(0, this.limit)

      const namedSuggests = []

      filteredData.forEach(item => {
        namedSuggests.push({ name: item.Name })
      })

      this.filteredOptions = [{
        data: namedSuggests,
      }]
    },

    /**
    * Evento de la selección del nombre
    *
    * @summary Establece al nombre el elemento seleccionado de las sugerencias
    * @param {object} item - Elemento seleccionado del campo de sugerencias del nombre
    */
    onSelectedName(item) {
      this.name = item.item.name
    },

    /**
    * Almacena una nueva marca
    *
    * @summary Almacena una nueva marca ingresada en el modal del formulario del producto
    * @param {string} brandName - Nombre de la marca
    * @exception - Mustra mensaje de error arrojado por el servidor
    */
    async handleBrandSaved(brandName) {
      try {
        await this.createBrand({
          name: brandName,
        })
        this.$refs.saveModalBrand.hideModal()
        this.showSweetAlert('¡Muy bien!', 'La marca se ha registrado correctamente.', 'success')

        await this.loadBrandsWithoutPagination(true)
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Almacena una nueva categoría
    *
    * @summary Almacena una nueva categoría ingresada en el modal del formulario del producto
    * @param {string} categoryName - Nombre de la categoría
    * @exception - Mustra mensaje de error arrojado por el servidor
    */
    async handleCategorySaved(categoryName) {
      try {
        await this.createCategory({
          name: categoryName,
          idCategory: this.selectedProcategory.IdTrueCategory,
        })
        this.$refs.saveModalCategory.hideModal()
        this.showSweetAlert('¡Muy bien!', 'La subcategoría se ha registrado correctamente.', 'success')

        await this.loadCategoriesWithoutPagination(true)
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Almacena un nuevo proveedor
    *
    * @summary Almacena un nuevo proveedor ingresado en el modal del formulario del producto
    * @param {string} supplierName - Nombre del proveedor
    * @exception - Mustra mensaje de error arrojado por el servidor
    */
    async handleSupplierSaved(supplierName) {
      try {
        await this.createSupplier({
          name: supplierName,
        })
        this.$refs.saveModalSupplier.hideModal()
        this.showSweetAlert('¡Muy bien!', 'El proveedor se ha registrado correctamente.', 'success')

        await this.loadSuppliersWithoutPagination(true)
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Cambio del tipo de producto
    *
    * @summary Validaciones y cambios en los estados de las piezas cuando se cambia el tipo de producto
    * @param {string} newValue - Nuevo tipo de producto seleccionado
    * @param {string} oldValue - Tipo de producto anterior a la selección
    */
    onTypeChange(newValue, oldValue) {
      if (!newValue || !oldValue) {
        if (newValue) {
          this.localPieces.forEach(piece => {
            this.$set(piece, 'Condition', newValue.text === 'Nuevo' ? 'Nuevo' : 'Habilitado')
          })
        }
        return
      }

      if (this.selectedType === newValue && this.canceledTypeChange) {
        this.canceledTypeChange = false
        return
      }

      if ((newValue.text === 'Usado' && oldValue.text === 'Remanufacturado')
          || (newValue.text === 'Remanufacturado' && oldValue.text === 'Usado')) {
        this.priceToBeDecided = false
        this.clearPrices()
        return
      }

      this.$swal({
        title: '¿Deseas continuar?',
        text: '¡Al cambiar el tipo de producto, el valor de la condición de las piezas cambiará!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Sí, cambiar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.selectedType = newValue
          this.canceledTypeChange = false

          this.priceToBeDecided = false
          this.clearPrices()
        } else {
          this.selectedType = oldValue
          this.canceledTypeChange = true

          this.item = this.backItem
          this.selectedSupplier = this.backSelectedSupplier

          this.minNormalPrice = this.backMinNormalPrice
          this.maxNormalPrice = this.backMaxNormalPrice
          this.minWholesalerPrice = this.backMinWholesalerPrice
          this.maxWholesalerPrice = this.backMaxWholesalerPrice

          // Establece los precios de respaldo para los productos usados
          this.lfPrice = this.backLfPrice
          this.drPrice = this.backDrPrice
          this.suggestMaxPrice = this.backSuggestMaxPrice
        }

        this.localPieces.forEach(piece => {
          this.$set(piece, 'Condition', this.selectedType.text === 'Nuevo' ? 'Nuevo' : 'Habilitado')
        })
      })

      if (!this.isNewProduct) {
        this.item = ''
        this.selectedSupplier = ''
        this.minNormalPrice = ''
        this.maxNormalPrice = ''
        this.minWholesalerPrice = ''
        this.maxWholesalerPrice = ''
      } else {
        this.lfPrice = ''
        this.drPrice = ''
        this.suggestMaxPrice = ''
      }
    },

    image01Loaded() {
      this.image01 = this.$refs.img01.file
    },

    image01Deleted() {
      this.image01 = null
    },

    image02Loaded() {
      this.image02 = this.$refs.img02.file
    },

    image02Deleted() {
      this.image02 = null
    },

    image03Loaded() {
      this.image03 = this.$refs.img03.file
    },

    image03Deleted() {
      this.image03 = null
    },

    changeLowCount(lowCount) {
      this.lowCount = lowCount
    },

    /**
    * Mensaje de alerta
    *
    * @summary Muestra un mensaje de alerta personalizado
    * @param {string} title - Título del mensaje
    * @param {string} text - Descripción del mensaje
    * @param {string} icon - Tipo de ícono del mensaje
    */
    showSweetAlert(title, text, icon) {
      this.$swal({
        title,
        text,
        icon,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
