<template>
  <b-card>
    <b-card-text>
      <b-container>
        <b-row>
          <b-col md="12">
            <h4>Descripción del producto</h4>
          </b-col>

          <!-- #region::Comment textarea -->
          <b-col md="12">
            <b-form-group
              label="Descripción"
              label-for="textarea-description"
            >

              <div
                slot="label"
                class="d-flex justify-content-start"
              >
                <span>Agrega una descripción</span>
              </div>

              <validation-provider
                #default="{ errors }"
                name="descripción"
                vid="Descripción"
              >

                <b-form-textarea
                  id="textarea-description"
                  v-model="description"
                  maxlength="600"
                  placeholder="Escribe la descripción"
                  rows="3"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- #endregion::Comment textarea -->
        </b-row>
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BCard, BCardText, BContainer, BRow, BCol, BFormTextarea, BFormGroup,
} from 'bootstrap-vue'

import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BContainer,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
  },
  computed: {
    ...mapGetters({
      getDescription: 'products/getDescription',
    }),
    /**
     * Descripción del producto
     */
    description: {
      get() { return this.getDescription },
      set(value) { this.setDescription(value) },
    },
  },
  methods: {
    ...mapActions({
      setDescription: 'products/setDescription',
    }),
  },
}
</script>

<style lang="scss" scoped>

</style>
