<template>
  <b-card>
    <b-card-text>
      <b-container>
        <b-row>
          <b-col md="12">
            <h4>Dimensiones del producto</h4>
          </b-col>

          <!-- #region begin::Product weight -->
          <b-col md="6">
            <b-row>
              <b-col md="8">
                <TextInputWithValidation
                  v-model="weight"
                  vid="weight"
                  rules="integer"
                  type="number"
                  label="Peso del producto"
                  name="peso del producto"
                  maxlength="12"
                  placeholder="Escribe el peso del producto"
                />
              </b-col>
              <b-col md="4">
                <SelectWithValidation
                  v-model="selectedWeight"
                  vid="selectedWeight"
                  name="u/m"
                  label="U/M"
                  property="text"
                  :options="getWeights"
                />
              </b-col>
            </b-row>
          </b-col>
          <!-- #endregion end::Product weight -->

          <!-- #region begin::Product measures -->
          <b-col
            md="6"
          >
            <b-row class="mt-1">
              <b-col
                md="12"
              >
                <small>Medidas del producto (cm)</small>
              </b-col>
              <b-col md="4">
                <TextInputWithValidation
                  v-model="length"
                  vid="length"
                  rules="integer"
                  type="number"
                  name="longitud"
                  maxlength="12"
                  placeholder="Logitud"
                  :styled="false"
                />
              </b-col>
              <b-col md="4">
                <TextInputWithValidation
                  v-model="width"
                  vid="width"
                  rules="integer"
                  type="number"
                  name="ancho"
                  maxlength="12"
                  placeholder="Ancho"
                  :styled="false"
                />
              </b-col>
              <b-col md="4">
                <TextInputWithValidation
                  v-model="height"
                  vid="heigh"
                  rules="integer"
                  type="number"
                  name="alto"
                  maxlength="12"
                  placeholder="Alto"
                  :styled="false"
                />
              </b-col>
            </b-row>
          </b-col>
          <!-- #endregion end::Product measures -->
        </b-row>
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BCard, BCardText, BContainer, BRow, BCol,
} from 'bootstrap-vue'

import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BContainer,
    SelectWithValidation,
    TextInputWithValidation,
  },
  computed: {
    ...mapGetters({
      getWidth: 'products/getWidth',
      getHeight: 'products/getHeight',
      getWeight: 'products/getWeight',
      getLength: 'products/getLength',
      getWeights: 'products/getWeights',
      getSelectedWeight: 'products/getSelectedWeight',
    }),
    /**
     * Anchura del producto
     */
    width: {
      get() { return this.getWidth },
      set(value) { this.setWidth(value) },
    },
    /**
     * Altura del producto
     */
    height: {
      get() { return this.getHeight },
      set(value) { this.setHeight(value) },
    },
    /**
     * Peso del producto
     */
    weight: {
      get() { return this.getWeight },
      set(value) { this.setWeight(value) },
    },
    /**
     * Largo del producto
     */
    length: {
      get() { return this.getLength },
      set(value) { this.setLength(value) },
    },
    /**
     * Tipo del peso seleccionado
     */
    selectedWeight: {
      get() { return this.getSelectedWeight },
      set(value) { this.setSelectedWeight(value) },
    },
  },
  methods: {
    ...mapActions({
      setWidth: 'products/setWidth',
      setHeight: 'products/setHeight',
      setWeight: 'products/setWeight',
      setLength: 'products/setLength',
      setSelectedWeight: 'products/setSelectedWeight',
    }),
  },
}
</script>
