<template>
  <b-card>
    <b-card-text>
      <b-container>
        <b-row>
          <b-col md="12">
            <h4>Precios del producto</h4>
          </b-col>

          <!-- #region begin::USD invoice unit price input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="unitPriceInvoiceUsd"
              vid="unitPriceInvoiceUsd"
              rules="decimal"
              type="text"
              label="Precio unitario factura USD"
              name="precio unitario factura USD"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
            />
          </b-col>
          <!-- #endregion end::USD invoice unit price input -->

          <!-- #region begin::Exchange rate input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="exchangeRate"
              vid="exchangeRate"
              rules="decimal"
              type="text"
              label="Tipo de cambio"
              name="tipo de cambio"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
            />
          </b-col>
          <!-- #endregion end::Exchange rate input -->

          <!-- #region begin::M/N invoice unit price input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="unitPriceInvoiceMn"
              vid="unitPriceInvoiceMn"
              rules="decimal"
              type="text"
              label="Precio unitario factura M/N"
              name="precio unitario factura MD/N"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
            />
          </b-col>
          <!-- #endregion end::M/N invoice unit price input -->

          <!-- #region begin::M/N utility / pay for MN'SC input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="costUtility"
              vid="costUtility"
              rules="decimal"
              type="text"
              label="Costeo MN'SC / Utilidad(5%) M/N"
              name="costeo MN'SC / Utilidad(5%) M/N"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
            />
          </b-col>
          <!-- #endregion end::M/N utility / pay for MN'SC input -->

          <!-- #region begin::USD supplier price input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="providersPriceUsd"
              vid="providersPriceUsd"
              rules="decimal"
              type="text"
              label="Precio proveedor USD"
              name="precio proveedor USD"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
            />
          </b-col>
          <!-- #endregion end::USD supplier price input -->

          <!-- #region begin::M/N supplier pay input -->
          <b-col md="3">
            <TextInputWithValidation
              v-model="providersCostingMn"
              vid="providersCostingMn"
              rules="decimal"
              type="text"
              label="Costeo proveedor M/N"
              name="costeo proveedor M/N"
              maxlength="12"
              placeholder="100.00"
              prepend="$"
            />
          </b-col>
          <!-- #endregion end::M/N supplier pay input -->

        </b-row>
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BCard, BCardText, BContainer, BRow, BCol,
} from 'bootstrap-vue'

import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BContainer,
    TextInputWithValidation,
  },
  computed: {
    ...mapGetters({
      getCostUtility: 'products/getCostUtility',
      getExchangeRate: 'products/getExchangeRate',
      getProvidersPriceUsd: 'products/getProvidersPriceUsd',
      getUnitPriceInvoiceMn: 'products/getUnitPriceInvoiceMn',
      getProvidersCostingMn: 'products/getProvidersCostingMn',
      getUnitPriceInvoiceUsd: 'products/getUnitPriceInvoiceUsd',
    }),
    /**
     * Costo de utilidad
     */
    costUtility: {
      get() { return this.getCostUtility },
      set(value) { this.setCostUtility(value) },
    },
    /**
     * Tipo de cambio
     */
    exchangeRate: {
      get() { return this.getExchangeRate },
      set(value) { this.setExchangeRate(value) },
    },
    /**
     * Precio en dolares para proveedores
     */
    providersPriceUsd: {
      get() { return this.getProvidersPriceUsd },
      set(value) { this.setProvidersPriceUsd(value) },
    },
    /**
     * Precio unitario de facturación en M/N
     */
    unitPriceInvoiceMn: {
      get() { return this.getUnitPriceInvoiceMn },
      set(value) { this.setUnitPriceInvoiceMn(value) },
    },
    /**
     * Costo para proveedores en M/N
     */
    providersCostingMn: {
      get() { return this.getProvidersCostingMn },
      set(value) { this.setProvidersCostingMn(value) },
    },
    /**
     * Precio unitario de facturación en M/N
     */
    unitPriceInvoiceUsd: {
      get() { return this.getUnitPriceInvoiceUsd },
      set(value) { this.setUnitPriceInvoiceUsd(value) },
    },
  },
  methods: {
    ...mapActions({
      setCostUtility: 'products/setCostUtility',
      setExchangeRate: 'products/setExchangeRate',
      setProvidersPriceUsd: 'products/setProvidersPriceUsd',
      setUnitPriceInvoiceMn: 'products/setUnitPriceInvoiceMn',
      setProvidersCostingMn: 'products/setProvidersCostingMn',
      setUnitPriceInvoiceUsd: 'products/setUnitPriceInvoiceUsd',
    }),
  },
}
</script>
